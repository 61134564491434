import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "components/Cards/PricingCards/DefaultPricingCard";

function ThesisWritingFeatures() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox component="section" variant="gradient" bgColor="dark" py={{ xs: 0, lg: 7 }}>
      <Container sx={{ pb: { xs: 18, lg: 22 }, pt: 4 }}>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          md={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h3" color="white" mb={1}>
            Thesis writing features
          </MKTypography>
          {/* <MKTypography variant="body2" color="white">
            Explore our flexible package options tailored to meet your needs.
          </MKTypography> */}
        </Grid>
      </Container>
      <MKBox mt={-16}>
        <Container>

          <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "thesis chapters" }}
                  price={{ currency: "$", value: tabType === "annual" ? 119 : 59, type: "mo" }}
                  specifications={[
                    { label: "Thesis Abstract", includes: true },
                    { label: "Thesis Introduction", includes: true },
                    { label: "Literature Review", includes: true },
                    { label: "Thesis Methods", includes: true },
                    { label: "Thesis Results", includes: true },
                    { label: "Thesis Discussion", includes: true },
                    { label: "Thesis Conclusion", includes: true },
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  color="dark"
                  badge={{ color: "info", label: "Thesis Subjects" }}
                  price={{ currency: "$", value: tabType === "annual" ? 159 : 89, type: "mo" }}
                  specifications={[
                    { label: "Philosophy & Law", includes: true },
                    { label: "Human resources", includes: true },
                    { label: "Accounting & Management", includes: true },
                    { label: "English Literature", includes: true },
                    { label: "Computer Science", includes: true },
                    { label: "Criminology, Sociology & Philosophy", includes: true },
                    { label: "And other Subjects", includes: true },
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "Referring Services" }}
                  price={{ currency: "$", value: tabType === "annual" ? 99 : 399, type: "mo" }}
                  specifications={[
                    { label: "Full Thesis", includes: true },
                    { label: "Thesis Proposal", includes: true },
                    { label: "Thesis Editing", includes: true },
                    { label: "Thesis Proofreading", includes: true },
                    { label: "Thesis Formatting", includes: true },
                    { label: "Annotated Bibliography", includes: true },
                    { label: "Thesis Writing", includes: true },
                  ]}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default ThesisWritingFeatures;