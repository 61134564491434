// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="payment"
                title="Comprehensive Thesis Writing Services"
                description="Get expert assistance for your thesis from concept to completion, ensuring quality and originality in every section."
              />
              <SimpleInfoCard
                icon="insights"
                title="Plagiarism-Free Guarantee and Revisions"
                description="We ensure all work is free from plagiarism and offer a revision period to address any concerns or updates needed."
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="access_alarms"
                title="Clarity and Comprehension"
                description="Our language is clear and concise, ensuring that every aspect of your thesis is easily understandable and accessible."
              />
              <SimpleInfoCard
                icon="sentiment_satisfied"
                title="Fast Turnaround Times with Quality Assurance"
                description="Delivering high-quality research papers and theses within your deadline without compromising on the standards."
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
