// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components

// Material Kit 2 PRO React examples
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";

function PlaceAnOrder() {
    const navigate = useNavigate();
    return (
        <MKBox component="section" pb={6} pt={12}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                    pb={6}
                >
                    <MKTypography variant="h2" mb={1}>
                        How to place an order
                    </MKTypography>
                    {/* <MKTypography variant="body1" color="text">
            We&apos;re constantly trying to express ourselves and actualize our dreams. If you have
            the opportunity to play{" "}
          </MKTypography> */}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <SimpleInfoCard
                            color="primary"
                            icon="person"
                            title="Submit instructions"
                            description="Fill out an order form and include as much detail as possible. Our team will then promptly furnish you with a personalized quote."
                            direction="center"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SimpleInfoCard
                            icon="support_agent"
                            title="Meet with writer"
                            description="Meet with a writer on Google Meet to discuss your project requirements, and subsequently add funds to initiate."
                            direction="center"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SimpleInfoCard
                            color="warning"
                            icon="update"
                            title="Track order"
                            description="Stay informed about the progress of your order or engage in communication with your writer whenever you need updates."
                            direction="center"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent={"center"} pt={6} >
                    <Grid item>
                    <MKButton color={"info"} variant={"contained"} onClick={()=>navigate("/new-project")}>
                        Place order now
                    </MKButton>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default PlaceAnOrder;