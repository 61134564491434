// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKDropdown from "components/MKDropdown";
import MKDatePicker from "components/MKDatePicker";
import Button from "assets/theme/components/button";
import FormControlLabel from "assets/theme/components/form/formControlLabel";
import SelectableButtonWithCheckbox from "../components/SelectableButtonWithCheckbox";
import { isEthicsRelatedProjectSelected, isJournalRelatedProjectSelected, isResearchRelatedProjectSelected } from "../utils";

function DetailedInformation({ state, dispatch, handleNext }) {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox width="100%" component="form" method="post" autoComplete="off">
            <MKBox p={3}>
              <Grid container spacing={1}>

                {(isJournalRelatedProjectSelected(state.documentNeeded) || isResearchRelatedProjectSelected(state.documentNeeded)) &&
                  <>
                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Objective
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The main objectives or goals of the research proposal" fullWidth
                        value={state.objective}
                        onChange={(evt) => { dispatch({ type: 'SET_OBJECTIVE', payload: evt.target.value }) }}
                        error={!state.validationState.objective}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Proposed title
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The working title for the research proposal (if available)" fullWidth
                        value={state.proposedTitle}
                        onChange={(evt) => { dispatch({ type: 'SET_PROPOSED_TITLE', payload: evt.target.value }) }}
                        error={!state.validationState.proposedTitle}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Type of research
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The general topic or area of research the proposal will cover" fullWidth
                        value={state.typeOfResearch}
                        onChange={(evt) => { dispatch({ type: 'SET_TYPE_OF_RESEARCH', payload: evt.target.value }) }}
                        error={!state.validationState.typeOfResearch}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Program of study
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The academic program the research proposal is intended for (if applicable)" fullWidth
                        value={state.programOfStudy}
                        onChange={(evt) => { dispatch({ type: 'SET_PROGRAM_OF_STUDY', payload: evt.target.value }) }}
                        error={!state.validationState.programOfStudy}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Research question
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The specific question the research proposal will address" fullWidth multiline rows={3}
                        value={state.researchQuestions}
                        onChange={(evt) => { dispatch({ type: 'SET_RESEARCH_QUESTIONS', payload: evt.target.value }) }}
                        error={!state.validationState.researchQuestions}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Method
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="A brief description of the research methods that will be used to achieve the research objectives" fullWidth
                        value={state.method}
                        onChange={(evt) => { dispatch({ type: 'SET_METHOD', payload: evt.target.value }) }}
                        error={!state.validationState.method}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Literature review
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="A summary of the current literature on the topic, including key concepts, theories, and research findings" fullWidth
                        value={state.literatureReview}
                        onChange={(evt) => { dispatch({ type: 'SET_LITERATURE_REVIEW', payload: evt.target.value }) }}
                        error={!state.validationState.literatureReview}
                      />
                    </Grid>
                  </>
                }

                {isJournalRelatedProjectSelected(state.documentNeeded) &&
                  <>
                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Specified journal
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The specific journal the article will be submitted to (if applicable)" fullWidth
                        value={state.specifiedJournal}
                        onChange={(evt) => { dispatch({ type: 'SET_SPECIFIED_JOURNAL', payload: evt.target.value }) }}
                        error={!state.validationState.specifiedJournal}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Journal format
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The specific format or style the journal requires (if applicable)" fullWidth
                        value={state.journalFormat}
                        onChange={(evt) => { dispatch({ type: 'SET_JOURNAL_FORMAT', payload: evt.target.value }) }}
                        error={!state.validationState.journalFormat}
                      />
                    </Grid>
                  </>
                }
                {isEthicsRelatedProjectSelected(state.documentNeeded) &&
                  <>
                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Type of plagiarism removal
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The type of plagiarism removal required" fullWidth
                        value={state.typeOfPlagiarismRemoval}
                        onChange={(evt) => { dispatch({ type: 'SET_TYPE_OF_PLAGIARISM_REMOVAL', payload: evt.target.value }) }}
                        error={!state.validationState.typeOfPlagiarismRemoval}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MKTypography variant="h6" color="dark" fontWeight="regular" >
                        Percentage of similarity index
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                      <MKInput label="The maximum percentage of similarity allowed in the final document" fullWidth
                        value={state.percentageOfSimilartyIndex}
                        onChange={(evt) => { dispatch({ type: 'SET_PERCENTAGE_OF_SIMILARITY_INDEX', payload: evt.target.value }) }}
                        error={!state.validationState.percentageOfSimilartyIndex}
                      />
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <MKTypography variant="h6" color="dark" fontWeight="regular" >
                    Additional requirements
                  </MKTypography>
                </Grid>
                <Grid item xs={12} mb={2} >
                  <MKInput label="Any additional requirements or instructions for the research proposal" fullWidth
                    value={state.additionalRequirements}
                    onChange={(evt) => { dispatch({ type: 'SET_ADDITIONAL_REQUIREMENTS', payload: evt.target.value }) }}
                    error={!state.validationState.additionalRequirements}
                  />
                </Grid>

              </Grid>

              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKButton type="button" variant="gradient" color="info"
                onClick={handleNext}
                >
                  Submit
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  )
}

export default DetailedInformation;