// import { IPaymentDetail, IPaymentDetailState, IPaymentReduxState, IPaymentState, IUserInfoState } from "../../types/redux"
// import { IPaymentDetailModelState, IUserInfoModelState } from "../../types/model"

// type Action =
//     | { type: 'SET_NEWLY_CREATED_PAYMENT_INFO'; payload: IPaymentDetailModelState }
//     | { type: 'SET_PAYMENT_STATE'; payload: IPaymentState }
//     | { type: 'SET_PAYMENT_DETAILS'; payload: IPaymentDetail[] }
//     | { type: 'SET_PAYMENT_DETAIL_STATE'; payload: IPaymentDetailState }


const initialState = {
    paymentOrderData: {
        amount: 0,
        orderId: "",
        createdAt: 0,
        currency: "INR"
    },
    paymentState: { state: "NOT_STARTED" },
    paymentDetails: [],
    paymentDetailState: { state: "NOT_STARTED" }
}

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NEWLY_CREATED_PAYMENT_INFO':
            return {
                ...state,
                amount: action.payload.amount,
                orderId: action.payload.orderId,
                createdAt: action.payload.createdAt
            }
        case 'SET_PAYMENT_STATE':
            return {
                ...state,
                paymentState: action.payload.state
            }
        case 'SET_PAYMENT_DETAILS':
            return {
                ...state,
                paymentDetails: action.payload,
                paymentDetailState: {
                    state: 'COMPLETE'
                }
            }
        case 'SET_PAYMENT_DETAIL_STATE':
            return {
                ...state,
                paymentDetailState: { state: action.payload }
            }
        case 'RESET_PAYMENT_DETAIL':
            return {
                ...state,
                paymentDetails: [],
                paymentDetailState: { state: "NOT_STARTED" }
            }
        default:
            return state;
    }
}

export default paymentReducer