

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useDispatch } from "react-redux";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ProjectCollapse from "../ProjectCollapse";
import BasicInfo from "../BasicInfo";
import DetailedInfo from "../DetailedInfo";
import PackageDetail from "../PackageDetail";
import ProjectTnc from "../ProjectTnc";
import PriceBreakup from "../PriceBreakup";
import PaidAmountBreakup from "../PaidAmountBreakup";
import { getDate_DDMMYYYY } from "utility/date";
import _ from 'lodash';

// Material Kit 2 PRO React components

// Material Kit 2 PRO React examples

// HelpCenter page components


// Routes



function ProjectMain({ project }) {

    return (
        <>
            <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="dark"
                p={3}
                mt={-3}
                mx={2}
            >
                <MKTypography variant="h3" color="white" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" width="90%">
                    {_.startCase(project.topicOfResearch)}
                </MKTypography>
                <MKTypography variant="body2" color="white" opacity={0.9}>
                    Submitted on: {getDate_DDMMYYYY(project.createdAt)}
                </MKTypography>
            </MKBox>
            <MKBox p={6}>

                <Grid container spacing={1}>
                    <Grid item xs={12} lg={8} >
                        <BasicInfo
                            project={project}
                        />
                        <DetailedInfo
                            project={project}
                        />
                        <PackageDetail
                            project={project}
                        />
                    </Grid>
                    <Grid item xs={12} lg={1} ></Grid>
                    <Grid item xs={12} lg={3}>
                        
                        { _.sumBy(project.prices, 'price') > 0 ?
                        <>
                            <PriceBreakup
                                project={project}
                            />
                            <PaidAmountBreakup
                                project={project}
                            />
                            </>
                            :<></>
                        }
                    </Grid>
                </Grid>


                <ProjectTnc />

            </MKBox>
        </>
    );
}

ProjectMain.defaultProps = {
    project: {}
}

ProjectMain.propTypes = {
    project: PropTypes.objectOf(PropTypes.string),
}

export default ProjectMain;