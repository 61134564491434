
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import ColoredBackgroundCard from "components/Cards/BackgroundCards/ColoredBackgroundCard";
import InfoBackgroundCard from "components/Cards/BackgroundCards/InfoBackgroundCard";
import DefaultBlogCard from "components/Cards/BlogCards/DefaultBlogCard";

// Author page sections
import Profile from "./sections/Profile";
import Posts from "./sections/Posts";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.webp";
import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function Author() {
  const userInfoState = useSelector(state => state.userInfoState);
  return (
    <>
      <Helmet>
        <title>Innovus Analytics Blogs | Expert Writing services in India</title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, research paper, content analysis, dissertation writing in india etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />

        <link rel="canonical" href="https://www.innovusanalytics.com/blog/" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="20rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {/* <Profile />
          <Posts /> */}

          <Container>
            <Grid
              container
              item
              xs={12}
              lg={6}
              flexDirection="column"
              justifyContent="center"
              mx="auto"
              mb={6}
            >
              <MKBox py={2} px={6} textAlign="center">
                <MKBox
                  width="4rem"
                  height="4rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  shadow="md"
                  borderRadius="lg"
                  mx="auto"
                >
                  <Icon fontSize="medium">person</Icon>
                </MKBox>
                <MKTypography variant="h2" mt={2} mb={1}>
                  Check out what&apos;s new
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  We get insulted by others, lose trust for those others. We get back freezes every
                  winter
                </MKTypography>
              </MKBox>
            </Grid>
          </Container>

          <MKBox component="section" py={7}>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultBlogCard
                    category={{ color: "primary", label: "research topic" }}
                    title="Research topics and it's evolution"
                    description="Following the internet revolution, research
                     methods have significantly evolved. Given the abundance of
                      information accessible and the global network connections..."
                    author={{
                      // image: author1,
                      name: "Admin",
                      date: "Posted on 04 March",
                    }}
                    action={{ type: "internal", route: "/blog/research-topic-are-evolving-post-internet-revolution" }}
                  />
                </Grid>

                <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultBlogCard
                    // image={post2}
                    category={{ color: "info", label: "questionnaire reliability" }}
                    title="Unveiling Questionnaire Reliability"
                    description="Cronbach's Alpha is widely regarded as the primary
                     method for assessing the internal consistency of a questionnaire.
                      It is important to recognize that although Cronbach's Alpha is
                       a valuable tool."
                    author={{
                      // image: author2,
                      name: "Admin",
                      date: "Posted on 27 February",
                    }}
                    action={{ type: "internal", route: "/blog/exploring-questionnaire-reliability-testing-beyond-cronbachs-alpha" }}
                  />
                </Grid>
                <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultBlogCard
                    category={{ color: "primary", label: "RESEARCH FINDINGS" }}
                    title="Enhancing Research Findings through Multi-Method Hypothesis Verification"
                    description="In the world of research, proving a theory is not a universal task.
                     It typically necessitates a comprehensive approach encompassing a variety of
                      approaches and disciplines."
                    author={{
                      // image: author3,
                      name: "Admin",
                      date: "Posted on 17 February",
                    }}
                    action={{ type: "internal", route: "/blog/verifying-hypothesis-with-multiple-methods" }}
                  />
                </Grid>

                <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultBlogCard
                    category={{ color: "info", label: "thesis writing" }}
                    title="How to write thesis"
                    description="Following the internet revolution, research
                     methods have significantly evolved. Given the abundance of
                      information accessible and the global network connections..."
                    author={{
                      // image: author1,
                      name: "Admin",
                      date: "Posted on 12 February",
                    }}
                    action={{ type: "internal", route: "/blog/how-to-write-thesis" }}
                  />
                </Grid>

                <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultBlogCard
                    category={{ color: "primary", label: "research paper" }}
                    title="How to write research paper"
                    description="Following the internet revolution, research
                     methods have significantly evolved. Given the abundance of
                      information accessible and the global network connections..."
                    author={{
                      // image: author1,
                      name: "Admin",
                      date: "Posted on 02 February",
                    }}
                    action={{ type: "internal", route: "/blog/how-to-write-research-papers" }}
                  />
                </Grid>
                <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultBlogCard
                    category={{ color: "primary", label: "Empirical analysis" }}
                    title="Steps to do Empirical Analysis"
                    description="Following the internet revolution, research
                     methods have significantly evolved. Given the abundance of
                      information accessible and the global network connections..."
                    author={{
                      // image: author1,
                      name: "Admin",
                      date: "Posted on 30 January",
                    }}
                    action={{ type: "internal", route: "/blog/how-to-do-emipirical-analysis" }}
                  />
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <Contact />
        <Footer />
      </MKBox>
    </>
  );
}

export default Author;
