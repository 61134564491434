

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import RefundCollapse from "./components/RefundCollapse";

// Routes
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function Refund() {
  const userInfoState = useSelector(state => state.userInfoState);
  return (
    <>
      <Helmet>
        <title>Innovus Analytics | Refund Policy | Expert Writing services in India | Refund Policy </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/refund-policy/" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>

        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white" component="h2">
                    Refund Policy
                  </MKTypography>
                </MKBox>
                <MKBox p={6}>
                  <RefundCollapse
                    title="Eligibility for Refund"
                  >
                    You must make a refund request within three days of making the advance payment in order to be eligible.
                    <br />
                    <br />
                    No refund will be granted once the necessary papers and analytics have been taken.
                  </RefundCollapse>
                  <RefundCollapse
                    title="Submitting a Refund Request"
                  >
                    Contact our customer support within 3 days from the date of advance payment. Provide
                    your order number, a detailed explanation of the issue, and any supporting documentation.
                  </RefundCollapse>
                  <RefundCollapse
                    title="Refund Processing"
                  >
                    Once your request is received and approved, we will initiate a refund to your original
                    method of payment. Refunds may take 3-5 days to process, depending on your payment provider.
                    <br />
                    <br />
                    If you have any questions about our Refund Policy, please contact
                    us at contactus@innovusanalytics.com.
                  </RefundCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Refund;
