import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "components/Cards/PricingCards/DefaultPricingCard";

function Pricing() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox component="section" variant="gradient" bgColor="dark" py={{ xs: 0, lg: 7 }}>
      <Container sx={{ pb: { xs: 18, lg: 22 }, pt: 8 }}>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          md={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h3" color="white" mb={2}>
            See our packages
          </MKTypography>
          <MKTypography variant="body2" color="white">
            Explore our flexible package options tailored to meet your needs.
          </MKTypography>
        </Grid>
      </Container>
      <MKBox mt={-16}>
        <Container>
          {/* <Grid container sx={{ mb: 6 }}>
            <Grid item xs={7} md={6} lg={4} sx={{ mx: "auto", textAlign: "center" }}>
              <AppBar position="static">
                <Tabs value={activeTab} onChange={handleTabType}>
                  <Tab
                    id="monthly"
                    label={
                      <MKBox py={0.5} px={2} color="inherit">
                        Monthly
                      </MKBox>
                    }
                  />
                  <Tab
                    id="annual"
                    label={
                      <MKBox py={0.5} px={2} color="inherit">
                        Annual
                      </MKBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid> */}
          <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "starter" }}
                  price={{ currency: "$", value: tabType === "annual" ? 119 : 59, type: "mo" }}
                  specifications={[
                    { label: "2 google meet session", includes: true },
                    { label: "Report with analytics", includes: true },
                    { label: "Novelty finding", includes: true },
                    { label: "Plagirism check", includes: true },
                    { label: "30 days validity", includes: false },
                    { label: "Presentation with video", includes: false },
                    { label: "Priority delivery", includes: false },
                  ]}
                  action={{
                    type: "internal",
                    route: "/new-project",
                    color: "dark",
                    label: "Get quote",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  color="dark"
                  badge={{ color: "info", label: "advanced" }}
                  price={{ currency: "$", value: tabType === "annual" ? 159 : 89, type: "mo" }}
                  specifications={[
                    { label: "4 google meet session", includes: true },
                    { label: "Report with analytics", includes: true },
                    { label: "Novelty finding", includes: true },
                    { label: "Plagirism check", includes: true },
                    { label: "30 days validity", includes: true },
                    { label: "Presentation with video", includes: true },
                    { label: "Priority delivery", includes: false },
                  ]}
                  action={{
                    type: "internal",
                    route: "/new-project",
                    color: "info",
                    label: "Get quote",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "premium" }}
                  price={{ currency: "$", value: tabType === "annual" ? 99 : 399, type: "mo" }}
                  specifications={[
                    { label: "4 google meet session", includes: true },
                    { label: "Report with analytics", includes: true },
                    { label: "Novelty finding", includes: true },
                    { label: "Plagirism check", includes: true },
                    { label: "30 days validity", includes: true },
                    { label: "Presentation with video", includes: true },
                    { label: "Priority delivery", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "/new-project",
                    color: "dark",
                    label: "Get quote",
                  }}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Pricing;