import axios from "axios";
import { createPaymentOrderAPI, getPaymentSuccessAPI, getPaymentsAPI } from "../../api/payment.api";
import logo from "../../assets/images/logos/logo.svg"
// import { IUserInfoState } from "../../types/redux";
import { Navigate } from "react-router-dom";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export const createPaymentOrder = (authentication,
    amount,
    currency,
    receipt,
    userName,
    userEmail,
    userNumber,
    transactionDescription,
    projectId) => {
    // Simulate an async login request and dispatch an action with the user info
    return async (dispatch) => {
        try {
            const res = await loadScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );
            dispatch({
                type: 'SET_PAYMENT_STATE',
                payload: {
                    state: "STARTED"
                }
            });
            const data = await createPaymentOrderAPI(authentication, amount, currency, receipt, projectId);
            if (data.status === 200) {
                const orderId = data.data.id;
                dispatch({
                    type: 'SET_NEWLY_CREATED_PAYMENT_INFO',
                    payload: {
                        "amount": amount,
                        "orderId": orderId,
                        "createdAt": data.data.created_at,
                        "currency": currency || "INR"
                    },
                });

                // Now open payment tab
                const razorpayOptions = {
                    key: process.env.REACT_APP_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
                    amount: amount.toString(),
                    currency: currency,
                    name: userName,
                    description: transactionDescription,
                    image: { logo },
                    order_id: orderId,
                    handler: async function (response) {
                        try {
                            const data = {
                                orderCreationId: orderId,
                                projectId: projectId,
                                razorpayPaymentId: response.razorpay_payment_id,
                                razorpayOrderId: response.razorpay_order_id,
                                razorpaySignature: response.razorpay_signature,
                                email: userEmail,
                                name: userName,
                                amount: amount/100,     // Payment gateway stores payment with multiple of 100
                                currency: currency
                            };

                            const successRes = await getPaymentSuccessAPI(data);
                            // alert(result.data.msg);
                            if (successRes.status === 200 || successRes.status === 201) {
                                dispatch({
                                    type: 'SET_PAYMENT_STATE',
                                    payload: {
                                        state: "COMPLETE"
                                    }
                                })
                            }
                            // Navigate({to:"/payment"});
                        }
                        catch (err) {
                            console.log('err: ', err);
                            dispatch({
                                type: 'SET_PAYMENT_STATE',
                                payload: {
                                    state: "FAILED"
                                }
                            });
                            // Navigate({to:"/payment"});
                        }
                    },
                    prefill: {
                        name: userName,
                        email: userEmail,
                        contact: userNumber || '',
                    },
                    // notes: {
                    //     address: "Prefilled notes",
                    // },
                    theme: {
                        color: "#61dafb",
                    },
                };

                const paymentObject = new window.Razorpay(razorpayOptions);
                paymentObject.open();

            } else {
                dispatch({
                    type: 'SET_PAYMENT_STATE',
                    payload: {
                        state: "FAILED"
                    }
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_PAYMENT_STATE',
                payload: {
                    state: "FAILED"
                }
            })
        }
    };
};

export const getPayments = (authentication) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'SET_PAYMENT_DETAIL_STATE',
                payload: 'LOADING'
            });
            const data = await getPaymentsAPI(authentication);
            if (data.status === 200) {
                const payments = data.data;
                const payload = payments.map((payment) => {
                    return {
                        paymentId: payment.paymentId,
                        amount: payment.amount,
                        paymentStatus: payment.paymentStatus,
                        createdAt: payment.createdAt,
                        projectId: payment.project
                    }
                })
                dispatch({
                    type: 'SET_PAYMENT_DETAILS',
                    payload
                });
            } else {
                dispatch({
                    type: 'SET_PAYMENT_DETAIL_STATE',
                    payload: 'FAILED'
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_PAYMENT_DETAIL_STATE',
                payload: 'FAILED',
            })
        }
    };
}