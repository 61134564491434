
import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";
import Separator from "pages/Authentication/components/Separator";
import Socials from "pages/Authentication/components/Socials";


// Images
import bgImage from "assets/images/bg-sign-in-basic.webp";
import { useDispatch, useSelector } from "react-redux";
import { loginUserUsingEmail } from "redux/userInfo/action";
import { Helmet } from "react-helmet";

function SignIn() {
  const navigate = useNavigate();
  const [isEmailOk, setIsEmailOk] = useState(true);
  const [email, setEmail] = useState('');
  const [isPasswordOk, setIsPasswordOk] = useState(true);
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const userInfoState = useSelector(state => state.userInfoState);
  const handleSubmit = (e) => {
      e.preventDefault()
      setIsEmailOk(email.length > 0);
      setIsPasswordOk(password.length > 0);

      if (email.length === 0 || password.length === 0) return;
      dispatch(loginUserUsingEmail(email, password));
      // props.handleClose();
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  useEffect(() => {
      if (userInfoState.isUserLoggedIn) {
          if (redirectUrl)
              navigate(redirectUrl);
          else
              navigate('/');
      }
  }, [userInfoState.isUserLoggedIn])

  const onSignupLinkClick = () => {
      if (redirectUrl)
          return '/signup?redirect=' + redirectUrl;
      else
          return '/signup';
  }

  return (
    <>
      <Helmet>
        <title>Thesis writing services | Expert Writing services in India | Sign in </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/signin/" />
      </Helmet>
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1} component="h3">
            Sign in
          </MKTypography>
          <MKTypography variant="button" color="white">
            Welcome back
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput type="email" label="Email" fullWidth
              error={!isEmailOk} value={email} onChange={(e) => setEmail(e.target.value)} />
            </MKBox>
            <MKBox >
              <MKInput type="password" label="Password" fullWidth
              error={!isPasswordOk} value={password} onChange={(e) => setPassword(e.target.value)}/>
            </MKBox>
            <MKBox pt={1}>
              <MKTypography variant="button" color="text" fontWeight="medium"  >
                Forgot password?{" "}
                <MKTypography
                  component={Link}
                  to="/resetpassword"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Reset now
                </MKTypography>
              </MKTypography>
            </MKBox>

            <MKBox mt={4}>
              <MKButton 
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                fullWidth>
                sign in
              </MKButton>
            </MKBox>
            <Separator />
            <Socials />
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to={onSignupLinkClick()}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
    </>
  );
}

export default SignIn;
