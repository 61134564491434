
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";
import Separator from "pages/Authentication/components/Separator";
import Socials from "pages/Authentication/components/Socials";


// Images
import bgImage from "assets/images/bg-sign-in-basic.webp";
import { Helmet } from "react-helmet";

function PageNotFound() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <Helmet>
        <title>Innovus Analytics- Page not found | Expert Writing services in India</title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, research paper, content analysis, dissertation writing in india etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/404/" />
      </Helmet>
      <BasicLayout image={bgImage}>
        <Card>

          <MKBox pt={4} pb={4} px={3}>
            <MKBox component="form" role="form">
              <MKTypography variant="h4" fontWeight="medium" color="dark" my={1} textAlign="center">
                Oops!
              </MKTypography>
              <MKTypography variant="h6" color="dark" textAlign="center">
                404 - Page not found
              </MKTypography>

              <MKBox mt={6}>
                <MKBox mt={3} mb={1} textAlign="center">
                  <MKButton variant="gradient" color="dark" fullWidth  >
                    <MKTypography
                      component={Link}
                      to="/"
                      // variant="button"
                      color="light"
                      fontWeight="medium"
                      textGradient
                    >
                      Go to home
                    </MKTypography>
                  </MKButton>
                </MKBox>
              </MKBox>


            </MKBox>
          </MKBox>
        </Card>
      </BasicLayout>
    </>
  );
}

export default PageNotFound;
