// import { IProjectDetailsReduxState } from "../../types/redux";
// import { IProjectDetailsModelState } from "../../types/model";
// import dayjs, { Dayjs } from "dayjs";


// type Action =
//     | { type: 'SET_PROJECT_DETAILS'; payload: IProjectDetailsModelState[] }
//     | { type: 'UPDATE_PROJECT_DETAILS'; payload: IProjectDetailsModelState }
//     | { type: 'SET_PROJECT_DETAILS_LOADING'; payload?: string }
//     | { type: 'SET_PROJECT_DETAILS_LOADING_FAILED'; payload: string }
//     | { type: 'SAVING_PROJECT_DETAILS'; payload: boolean }
//     | { type: 'SET_PROJECT_DETAILS_SAVED'; }
//     | { type: 'RESET_PROJECT_SAVED_STATE' }
//     | { type: 'RESET_PROJECT_DETAILS' }


const initialState = {
    // projectDetails: undefined,
    // projectDetails: [{
    //     projectId: 'AB10324',
    //     status: 'In progress',
    //     prices: [{ label: 'Research Proposal charge', price: 1000 }, { label: 'Research Report', price: 2000 }],
    //     // amountPaid: [{ amount: 100, date: '2022-12-01T00:00:00.000Z' }, { amount: 2000, date: '2021-09-01T00:00:00.000Z' }],
    //     pursuingField: "this is pursuing field",
    //     domainOfResearch: "this is domain of research",
    //     topicOfResearch: "this is topic of research this is topic of research this is topic of research this is topic of research",
    //     documentNeeded: ["Research proposal", "Research report"],
    //     submissionDate: "2021-09-01T00:00:00.000Z",
    //     objective: "this is objective",
    //     proposedTitle: 'this s proposed tiele',
    //     typeOfResearch: 'this is type of research',
    //     programOfStudy: 'this is program of study',
    //     researchQuestions: [' this is first qus', 'this is second qus'],
    //     method: 'this is method',
    //     literatureReview: 'this is literature review',
    //     additionalRequirements: 'this is additional requirements',
    //     specifiedJournal: 'this is specified journal',
    //     journalFormat: 'this is journal format',
    //     typeOfPlagiarismRemoval: 'this is type of plagiarism removal',
    //     percentageOfSimilartyIndex: 'this is percentage of similarity index',
    //     packageDetails: {
    //         packageName: 'Beginner package',
    //         packagePrice: 23000,
    //         packageDescription: 'this is package description',
    //         packageFeatures: ['this is feature 1', 'this is feature 2', 'this is feature 3'],
    //     },
    //     createdAt: "2024-01-14T16:29:51.160Z",
    //     paymentStatus: 0,
    //     payments: [
    //         {
    //             paymentId: 'paymentId1',
    //             amount: 100,
    //             createdAt: '2022-12-01T00:00:00.000Z'
    //         },
    //         {
    //             paymentId: 'paymentId2',
    //             amount: 2000,
    //             createdAt: '2021-09-01T00:00:00.000Z'
    //         },],

    // }],
    projectDetails: [],
    projectDetailsLoadingState: {
        isLoading: false,
        isLoaded: false,
        isLoadingFailed: false,
        loadingMessage: ""
    },
    projectSavingState: {
        isSaving: false,
        isSaved: false,
    }
}

const projectDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PROJECT_DETAILS':
            // let projectDetails = [...(state.projectDetails || [])];
            let projectDetails = []
            action.payload.map((detail) => {
                let tmpDetail = detail;
                tmpDetail.status = tmpDetail.status;
                projectDetails.push(tmpDetail);
            })
            return {
                ...state,
                projectDetails: projectDetails,
                projectDetailsLoadingState: {
                    isLoading: false,
                    isLoaded: true,
                    isLoadingFailed: false,
                    loadingMessage: ""
                },
            }
        case 'UPDATE_PROJECT_DETAILS':
            {
                let projectDetails = [...(state.projectDetails || [])];
                let index = projectDetails.findIndex((project) => project.projectId === action.payload.projectId);
                if (index !== -1) {

                    let newProject = {
                        ...action.payload,
                        createdAt: projectDetails[index].createdAt
                    }
                    projectDetails.splice(index, 1);
                    projectDetails.push(newProject);
                }
                return {
                    ...state,
                    projectDetails: projectDetails,
                    projectDetailsLoadingState: {
                        isLoading: false,
                        isLoaded: true,
                        isLoadingFailed: false,
                        loadingMessage: ""
                    },
                }
            }
        case 'SET_PROJECT_DETAILS_LOADING':
            return {
                ...state,
                projectDetailsLoadingState: {
                    isLoading: true,
                    isLoaded: false,
                    isLoadingFailed: false,
                    loadingMessage: ""
                }
            }
        case 'SET_PROJECT_DETAILS_LOADING_FAILED':
            return {
                ...state,
                projectDetailsLoadingState: {
                    isLoading: false,
                    isLoaded: false,
                    isLoadingFailed: true,
                    loadingMessage: action.payload
                },
                projectSavingState: {
                    isSaving: false,
                    isSaved: false,
                }
            }
        case 'SAVING_PROJECT_DETAILS':
            return {
                ...state,
                projectSavingState: {
                    isSaving: true,
                    isSaved: false,
                }
            }
        case 'SET_PROJECT_DETAILS_SAVED':
            return {
                ...state,
                projectSavingState: {
                    isSaving: false,
                    isSaved: true,
                }
            }
        case 'RESET_PROJECT_SAVED_STATE':
            return {
                ...state,
                projectSavingState: {
                    isSaving: false,
                    isSaved: false,
                }
            }
        case 'RESET_PROJECT_DETAILS':
            return {
                projectDetails: [],
                projectDetailsLoadingState: {
                    isLoading: false,
                    isLoaded: false,
                    isLoadingFailed: false,
                    loadingMessage: ""
                },
            }
        default: return state
    }
}

export default projectDetailsReducer;