

import { useEffect, useState } from "react";
import ProjectCollapse from "../ProjectCollapse";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import { Grid } from "@mui/material";
import _ from 'lodash';

// @mui material components

// Material Kit 2 PRO React components


// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes




function BasicInfo({ project }) {


    return (
        <>
            <ProjectCollapse
                title="Basic Information"
            >
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Project ID
                </MKTypography>
                {project.projectId}
                <br />
                <br />
                {project.domainOfResearch.length > 0 &&
                    <>
                        <MKTypography variant="body2" color="dark" fontWeight="regular">
                            Domain of Research
                        </MKTypography>
                        {_.startCase(project.domainOfResearch)}
                        <br />
                        <br />
                    </>
                }

                {project.topicOfResearch.length > 0 &&
                    <>
                        <MKTypography variant="body2" color="dark" fontWeight="regular">
                            Topic of research
                        </MKTypography>
                        {_.startCase(project.topicOfResearch)}
                        <br />
                        <br />
                    </>
                }
                <Grid container>
                    <MKTypography variant="body2" color="dark" fontWeight="regular">
                        Status 
                    </MKTypography>
                    <MKBox
                        variant="contained"
                        bgColor="info"
                        borderRadius="xxl"
                        coloredShadow="dark"
                        px={1}
                        mx={2}
                    >
                        <MKTypography variant="body2" color="white" fontWeight="regular">
                            {project.status}
                        </MKTypography>
                    </MKBox>
                </Grid>

                <br />


                <>
                    <MKTypography variant="body2" color="dark" fontWeight="regular">
                        Types of document
                    </MKTypography>
                    {project.documentNeeded.map((item, index) => {
                        return (
                            <>
                                &#8226;&nbsp;&nbsp;  { _.startCase(_.toLower(item.replace(/_/g, ' ')))}&nbsp;
                            </>
                        )
                    })}
                    {/* _.map(omittedProject.documentNeeded, documentNeeded => _.startCase(_.toLower(documentNeeded.replace(/_/g, ' ')))); */}

                </>

            </ProjectCollapse >
        </>
    );
}

BasicInfo.defaultProps = {
    project: {}
}

BasicInfo.propTypes = {
    project: PropTypes.objectOf(PropTypes.string),
}

export default BasicInfo;
