import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import MKBox from "components/MKBox"
import MKButton from "components/MKButton"
import MKTypography from "components/MKTypography"

function SelectableButtonLabel({ label, description }) {
    return (
        <MKBox component={'div'} sx={{
            marginLeft: '1rem',
        }}>
            {/* <Typography component={'p'} sx={{
                fontSize: '1rem',
                fontWeight: '500',
                textTransform: 'none',
                textAlign: 'start',
                color: 'text.primary'
            }}>
                {label}
            </Typography> */}
            <MKTypography variant={"h6"} fontWeight={"regular"} color={"dark"}  >
                {label}
            </MKTypography>
            {/* {description && <Typography component={'p'} sx={{
                fontSize: '0.8rem',
                fontWeight: '500',
                color: 'text.secondary',
                textTransform: 'none',
                textAlign: 'start',
            }}>
                {description}
            </Typography>} */}
            <MKTypography variant={"tiny"} fontWeight={"regular"} color={"text"} >
                {description}
            </MKTypography>
        </MKBox>
    )
}

function SelectableButtonWithCheckbox({ label, description, checked, onClick, error }) {
    return (

        <MKButton variant="outlined" color={error? "error": "text"} sx={{
            width: "100%"
        }} error={error} onClick={onClick}  >
            <>
                <Grid container alignItems={"center"}>
                    <Grid item xs={1}>
                        <Checkbox checked={checked} />
                    </Grid>
                    <Grid item xs={11}>
                        <SelectableButtonLabel
                            label={label}
                            description={description}
                        />
                    </Grid>
                </Grid>

            </>
        </MKButton>

        // <FormControlLabel
        //     control={<Checkbox
        //         //  checked={props.checked} onChange={props.onChange}
        //           />}
        //     label={<SelectableButtonLabel 
        //         label={label} 
        //         description={description} 
        //         />}
        //     sx={{
        //         // marginInline: '1rem',
        //     }}
        // />
    )
}

export default SelectableButtonWithCheckbox