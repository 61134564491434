import axios from "axios";

export const getUserDetailInfoAPI = (authentication) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/user');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.get(url.toString(), { headers });
}

export const setUserDetailInfoAPI = (authentication, data) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/user');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.put(url.toString(), data, {headers});
}