
const initialState = {
    loadingState: "NOT_STARTED"
}

const contactUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CONTACT_US_STATE':
            return {
                ...state,
                loadingState : action.payload
            }
        default:
            return state;
    }
}

export default contactUsReducer;