

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes

import { LinearProgress, Skeleton } from "@mui/material";



function ProjectNotFound({id}) {


  return (
    <>
        <MKBox
            variant="gradient"
            bgColor="dark"
            borderRadius="lg"
            coloredShadow="dark"
            p={3}
            mt={-3}
            mx={2}
        >
            {/* <Skeleton variant="text" sx={{ fontSize: '3rem', bgcolor: 'white' }} /> */}
            <MKTypography variant="h3" color="white" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" width="90%">
                Project Not Found
            </MKTypography>

        </MKBox>
        <MKBox p={6}>

            <MKTypography variant="body2" color="dark" fontWeight="regular" pt={3} pb={8}>
                Project# {id} not found.
            </MKTypography>

        </MKBox>
    </>
  );
}


ProjectNotFound.defaultProps = {
    id: ""
}

ProjectNotFound.propTypes = {
    id: PropTypes.string,
}

export default ProjectNotFound;
