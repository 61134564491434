

import React from 'react'
import { Helmet } from 'react-helmet'

function HeadMetaTag() {
    return (
        <Helmet>
            <title>Innovus Analytics | Expert Writing services in India</title>
            <meta name="description" content="Academic services thesis writing service, research synopsis, research paper, content analysis, dissertation writing in india etc." />
            <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
            <link rel="canonical" href="https://www.innovusanalytics.com/" />
            <script type="application/ld+json">
                {`
                {
                "@context": "http://schema.org",
                "@graph": [
                    {
                    "@type": [
                        "EducationalOrganization",
                        "Organization"
                    ],
                    "name": "Innovus Analytics",
                    "url": "https://www.innovusanalytics.com",
                    "logo": "https://www.innovusanalytics.com/logo.png",
                    "sameAs": [
                        "https://www.facebook.com/innovusanalytics",
                        "https://www.twitter.com/innovusanalytics"
                    ],
                    "description": "Academic services thesis writing service, research synopsis, research paper, content analysis, dissertation writing in India, etc.",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Street X. 540",
                        "addressLocality": "Delhi",
                        "addressRegion": "Delhi",
                        "addressCountry": "India"
                    },
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "contactType": "customer service",
                        "telephone": "+1-800-555-5555",
                        "email": "contactus@innovusanalytics.com"
                    },
                    "service": [
                        {
                            "@type": "Service",
                            "serviceType": "Thesis Writing Service",
                            "description": "Get expert assistance for your thesis from concept to completion, ensuring quality and originality in every section."
                        },
                        {
                            "@type": "Service",
                            "serviceType": "Research Paper Writing",
                            "description": "Our experts craft high-impact research papers tailored to your specific requirements, ensuring adherence to journal standards."
                        },
                        {
                            "@type": "Service",
                            "serviceType": "Content Analysis",
                            "description": "We provide detailed content analysis and data interpretation to support your research findings with accuracy and clarity."
                        }
                    ]
                    },
                    {
                    "@type": "WebSite",
                    "@id": "https://www.innovusanalytics.com/#website",
                    "url": "https://www.innovusanalytics.com",
                    "publisher": {
                        "@id": "https://www.innovusanalytics.com/#organization"
                    },
                    "inLanguage": "en-US"
                    },
                    {
                    "@type": "ImageObject",
                    "@id": "https://www.innovusanalytics.com/logo.png",
                    "url": "https://www.innovusanalytics.com/logo.png",
                    "width": 1140,
                    "height": 114,
                    "caption": "Innovus analytics",
                    "inLanguage": "en-US"
                    },
                    {
                    "@type": "WebPage",
                    "@id": "https://www.innovusanalytics.com/#webpage",
                    "url": "https://www.innovusanalytics.com/",
                    "name": "Academic services thesis writing service, research synopsis, research paper, content analysis, dissertation writing and publication service in india, ",
                    "datePublished": "2024-08-25T04:54:11+00:00",
                    "dateModified": "2024-08-07T04:50:32+00:00",
                    "about": {
                        "@id": "https://www.innovusanalytics.com/#organization"
                    },
                    "isPartOf": {
                        "@id": "https://www.innovusanalytics.com/#website"
                    },
                    "inLanguage": "en-US"
                    },
                    {
                    "@type": "Person",
                    "@id": "https://www.innovusanalytics.com/about-us/",
                    "name": "admin",
                    "url": "https://www.innovusanalytics.com/about-us/",
                    "image": {
                        "@type": "ImageObject",
                        "@id": "https://www.innovusanalytics.com/research-paper-services/78fd50c8fdf17555039be74e256d6a46?s=96&amp;d=mm&amp;r=g",
                        "url": "https://secure.gravatar.com/avatar/78fd50c8fdf17555039be74e256d6a46?s=96&amp;d=mm&amp;r=g",
                        "caption": "admin",
                        "inLanguage": "en-US"
                    },
                    "sameAs": [
                        "https://www.innovusanalytics.com"
                    ],
                    "worksFor": {
                        "@id": "https://www.innovusanalytics.com/#organization"
                    }
                    },
                    {
                    "@type": "Article",
                    "headline": "Academic services thesis writing service, research synopsis, research paper, content analysis, dissertation writing and publication service in india",
                    "keywords": "thesis writing, research paper writing, dissertation",
                    "datePublished": "2022-08-25T04:54:11+00:00",
                    "dateModified": "2024-08-07T04:50:32+00:00",
                    "author": {
                        "@id": "https://www.innovusanalytics.com/about-us/",
                        "name": "admin"
                    },
                    "publisher": {
                        "@id": "https://www.innovusanalytics.com/#organization"
                    },
                    "description": "Thesis Writing, Research paper writing and content analysis Services in Delhi and pan India, a universal standard solution for all your research needs and accomplishments. Starting from research topic selection up to the accomplishment of your research, we will be your fundamental background support. Our customized support offers you a solution for your wide range of requirements under one roof. We are enlisted among the leading services for our trustworthy and well furnished PhD thesis writing service. Our skilled professionals closely work with our scholars to mine their requirements and offer them best service. Our continuous effort and service, makes us to stand high in the midst of numerous other concerns. We always remain as best and hope for the best. Be a part of our best offerings to upgrade your profile and career.",
                    "name": "Academic services thesis writing service, research synopsis, research paper, content analysis, dissertation writing and publication service in india",
                    "@id": "https://www.innovusanalytics.com/#richSnippet",
                    "isPartOf": {
                        "@id": "https://www.innovusanalytics.com/#webpage"
                    },
                    "image": {
                        "@id": "https://www.innovusanalytics.com/logo.png"
                    },
                    "inLanguage": "en-US",
                    "mainEntityOfPage": {
                        "@id": "https://www.innovusanalytics.com/#webpage"
                    }
                }
                ]
                }    

            `}
            </script>
        </Helmet>
    )
}

export default HeadMetaTag