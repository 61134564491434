import axios from "axios";

export const loginUsingEmailAPI = (email, password) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/auth/email');
    return axios.post(url.toString(), {email, password});
}

export const signupUsingEmailAPI = (name, email, password) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/register');
    return axios.post(url.toString(), {name, email, password});
}
