import { useEffect } from "react";
import { fetchProjectDetailAPI, fetchProjectsAPI, saveProjectDetailAPI, updateProjectDetailAPI } from "../../api/project.api";
// import { IProjectDetailsModelState } from "../../types/model";

export const fetchProjectDetail = (projectId, authentication) => {
    // Simulate an async login request and dispatch an action with the user info
    // return async (dispatch) => {
    //     try {
    //         dispatch({
    //             type: 'SET_PROJECT_DETAILS_LOADING',
    //         });
    //         const data = await fetchProjectDetailAPI(projectId, authentication);
    //         if (data.status === 200) {
    //             dispatch({
    //                 type: 'SET_PROJECT_DETAILS',
    //                 payload: data.data,
    //             });
    //         } else {
    //             dispatch({
    //                 type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
    //                 payload: 'Failed to fetch project details'
    //             })
    //         }
    //     } catch (error) {
    //         dispatch({
    //             type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
    //             payload: 'Failed to fetch project details',
    //         })
    //     }
    // };
};

export const getProjects = (authentication) => {
    // Simulate an async login request and dispatch an action with the user info
    return async (dispatch) => {
        try {
            dispatch({
                type: 'SET_PROJECT_DETAILS_LOADING',
            });
            const data = await fetchProjectsAPI(authentication);
            if (data.status === 200) {
                dispatch({
                    type: 'SET_PROJECT_DETAILS',
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
                    payload: 'Failed to fetch project details'
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
                payload: 'Failed to fetch project details',
            })
        }
    };
};

export const setProjectDetails = (projectDetail, authentication) => {
    // return {
    //     type: 'SET_PROJECT_DETAILS',
    //     payload: projectDetails
    // }

    return async (dispatch) => {
        try {
            dispatch({
                type: 'SAVING_PROJECT_DETAILS',
            });
            let data = {}
            // data.statusCode = projectDetail.statusCode;
            data.status = projectDetail.status;
            data.prices = projectDetail.prices;
            if (projectDetail.pursuingField.length > 0) data.pursuingField = projectDetail.pursuingField;
            if (projectDetail.domainOfResearch.length > 0) data.domainOfResearch = projectDetail.domainOfResearch;
            if (projectDetail.topicOfResearch.length > 0) data.topicOfResearch = projectDetail.topicOfResearch;
            if (projectDetail.documentNeeded.length > 0) data.documentNeeded = projectDetail.documentNeeded;
            data.submissionDate = projectDetail.submissionDate;
            if (projectDetail.objective && projectDetail.objective.length > 0) data.objective = projectDetail.objective;
            if (projectDetail.proposedTitle && projectDetail.proposedTitle.length > 0) data.proposedTitle = projectDetail.proposedTitle;
            if (projectDetail.typeOfResearch && projectDetail.typeOfResearch.length > 0) data.typeOfResearch = projectDetail.typeOfResearch;
            if (projectDetail.programOfStudy && projectDetail.programOfStudy.length > 0) data.programOfStudy = projectDetail.programOfStudy;
            if (projectDetail.researchQuestions && projectDetail.researchQuestions.length > 0) data.researchQuestions = projectDetail.researchQuestions;
            if (projectDetail.method && projectDetail.method.length > 0) data.method = projectDetail.method;
            if (projectDetail.literatureReview && projectDetail.literatureReview.length > 0) data.literatureReview = projectDetail.literatureReview;
            if (projectDetail.additionalRequirements && projectDetail.additionalRequirements.length > 0) data.additionalRequirements = projectDetail.additionalRequirements;
            if (projectDetail.specifiedJournal && projectDetail.specifiedJournal.length > 0) data.specifiedJournal = projectDetail.specifiedJournal;
            if (projectDetail.journalFormat && projectDetail.journalFormat.length > 0) data.journalFormat = projectDetail.journalFormat;
            if (projectDetail.typeOfPlagiarismRemoval && projectDetail.typeOfPlagiarismRemoval.length > 0) data.typeOfPlagiarismRemoval = projectDetail.typeOfPlagiarismRemoval;
            if (projectDetail.percentageOfSimilartyIndex && projectDetail.percentageOfSimilartyIndex.length > 0) data.percentageOfSimilartyIndex = projectDetail.percentageOfSimilartyIndex;
            if (projectDetail.packageDetails) data.packageDetails = projectDetail.packageDetails;

            const resData = await saveProjectDetailAPI(data, authentication);
            if (resData.status === 200) {
                // dispatch({
                //     type: 'SET_PROJECT_DETAILS',
                //     payload: [resData.data],
                // });
                dispatch({
                    type: 'SET_PROJECT_DETAILS_SAVED',
                });
                dispatch({
                    type: 'RESET_PROJECT_DETAILS'
                })
            } else {
                dispatch({
                    type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
                    payload: 'Failed to fetch project details'
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
                payload: 'Failed to fetch project details',
            })
        }
    };
}

export const updateProjectDetails = (projectDetail, authentication) => {
    // return {
    //     type: 'SET_PROJECT_DETAILS',
    //     payload: projectDetails
    // }

    return async (dispatch) => {
        try {
            dispatch({
                type: 'SAVING_PROJECT_DETAILS',
            });

            let data = {}
            data.statusCode = projectDetail.statusCode;
            data.prices = projectDetail.prices;
            data.projectId = projectDetail.projectId;
            if (projectDetail.pursuingField.length > 0) data.pursuingField = projectDetail.pursuingField;
            if (projectDetail.domainOfResearch.length > 0) data.domainOfResearch = projectDetail.domainOfResearch;
            if (projectDetail.topicOfResearch.length > 0) data.topicOfResearch = projectDetail.topicOfResearch;
            if (projectDetail.documentNeeded.length > 0) data.documentNeeded = projectDetail.documentNeeded;
            data.submissionDate = projectDetail.submissionDate;
            if (projectDetail.objective && projectDetail.objective.length > 0) data.objective = projectDetail.objective;
            if (projectDetail.proposedTitle && projectDetail.proposedTitle.length > 0) data.proposedTitle = projectDetail.proposedTitle;
            if (projectDetail.typeOfResearch && projectDetail.typeOfResearch.length > 0) data.typeOfResearch = projectDetail.typeOfResearch;
            if (projectDetail.programOfStudy && projectDetail.programOfStudy.length > 0) data.programOfStudy = projectDetail.programOfStudy;
            if (projectDetail.researchQuestions && projectDetail.researchQuestions.length > 0) data.researchQuestions = projectDetail.researchQuestions;
            if (projectDetail.method && projectDetail.method.length > 0) data.method = projectDetail.method;
            if (projectDetail.literatureReview && projectDetail.literatureReview.length > 0) data.literatureReview = projectDetail.literatureReview;
            if (projectDetail.additionalRequirements && projectDetail.additionalRequirements.length > 0) data.additionalRequirements = projectDetail.additionalRequirements;
            if (projectDetail.specifiedJournal && projectDetail.specifiedJournal.length > 0) data.specifiedJournal = projectDetail.specifiedJournal;
            if (projectDetail.journalFormat && projectDetail.journalFormat.length > 0) data.journalFormat = projectDetail.journalFormat;
            if (projectDetail.typeOfPlagiarismRemoval && projectDetail.typeOfPlagiarismRemoval.length > 0) data.typeOfPlagiarismRemoval = projectDetail.typeOfPlagiarismRemoval;
            if (projectDetail.percentageOfSimilartyIndex && projectDetail.percentageOfSimilartyIndex.length > 0) data.percentageOfSimilartyIndex = projectDetail.percentageOfSimilartyIndex;
            if (projectDetail.packageDetails) data.packageDetails = projectDetail.packageDetails;

            const resData = await updateProjectDetailAPI(projectDetail.projectId, data, authentication);
            if (resData.status === 201) {
                // Hack to check date from dayjs to string format for serialization
                projectDetail.submissionDate = projectDetail.submissionDate.format('YYYY-MM-DDTHH:mm:ssZ');
                dispatch({
                    type: 'UPDATE_PROJECT_DETAILS',
                    payload: projectDetail,
                });
                dispatch({
                    type: 'SET_PROJECT_DETAILS_SAVED',
                });
            } else {
                console.log("Failed setting failed")
                dispatch({
                    type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
                    payload: 'Failed to fetch project details'
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
                payload: 'Failed to fetch project details',
            })
        }
    };
}

export const setProjectDetailsLoading = (loadingMessage) => {
    return {
        type: 'SET_PROJECT_DETAILS_LOADING',
        payload: loadingMessage
    }
}

export const setProjectDetailsLoadingFailed = (loadingMessage) => {
    return {
        type: 'SET_PROJECT_DETAILS_LOADING_FAILED',
        payload: loadingMessage
    }
}

export const resetProjectDetailsSavedState = () => {
    return {
        type: 'RESET_PROJECT_SAVED_STATE'
    }
}

export const resetProjectDetails = () => {
    return {
        type: 'RESET_PROJECT_DETAILS'
    }
}