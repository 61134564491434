// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";
import AboutUs from "pages/Company/AboutUs";
import SignIn from "pages/Authentication/SignIn";
import ThesisWritingService from "pages/LandingPages/ThesisWritingService";
import ResearchPaperService from "pages/LandingPages/ResearchPaper";
import MyProjects from "pages/MyAccount/Dashboard/components/MyProjects";
import Payments from "pages/MyAccount/Dashboard/components/Payments";
import Profile from "pages/MyAccount/Dashboard/components/Profile";
import SignOut from "pages/Authentication/Signout";
import ContentAnalysisService from "pages/LandingPages/ContentAnalysisService";

const routes = [
  {
    name: "services",
    icon: <Icon>dashboard</Icon>,
    // columns: 1,
    // rowsPerColumn: 2,
    collapse: [
      {
        name: "Thesis Writing services",
        route: "/thesis-writing-services",
        component: <ThesisWritingService/>,
        description: "See all services",
        dropdown: true,
        collapse: [
          {
            name: "literature review",
            route: "/thesis-writing-services/literature-review",
            component: <ThesisWritingService />,
          },
          {
            name: "social analysis",
            route: "/thesis-writing-services/social-analysis",
            component: <ThesisWritingService />,
          },
          {
            name: "legal analysis",
            route: "/thesis-writing-services/legal-analysis",
            component: <ThesisWritingService />,
          },
        ],
      },
      {
        name: "research papaer",
        route: "/research-paper-services",
        description: "See all services",
        dropdown: true,
        collapse: [
          {
            name: "review paper",
            route: "/research-paper-services/review-paper",
            component: <ResearchPaperService />,
          },
          {
            name: "research article",
            route: "/research-paper-services/research-article",
            component: <ResearchPaperService />,
          },
          {
            name: "conference paper",
            route: "/research-paper-services/conference-paper",
            component: <ResearchPaperService />,
          },
          {
            name: "theoretical paper",
            route: "/research-paper-services/theoretical-paper",
            component: <ResearchPaperService />,
          },
        ],
      },
      {
        name: "content analysis",
        route: "/content-analysis-services",
        description: "See all services",
        dropdown: true,
        collapse: [
          {
            name: "empirical analysis",
            route: "/content-analysis-services/empirical-analysis",
            component: <ContentAnalysisService />,
          },
          {
            name: "discourse analysis",
            route: "/content-analysis-services/discourse-analysis",
            component: <ContentAnalysisService />,
          },
          {
            name: "descriptive analysis",
            route: "/content-analysis-services/descriptive-analysis",
            component: <ContentAnalysisService />,
          },
          {
            name: "meta analysis",
            route: "/content-analysis-services/meta-analysis",
            component: <ContentAnalysisService />,
          },
        ]
      },
    ],
  },
  {
    name: "about us",
    icon: <Icon>view_day</Icon>,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "sign in",
    icon: <Icon>login</Icon>,
    route: "/signin",
    visibleIfLoggedIn: false,
    component: <SignIn />,
  },
  {
    name: "my account",
    icon: <Icon>login</Icon>,
    visibleIfLoggedIn: true,
    collapse: [
      {
        name: "my project",
        route: "/projects",
        component: <MyProjects />
      },
      {
        name: "payments",
        route: "/payments",
        component: <Payments />
      },
      {
        name: "profile",
        route: "/profile",
        component: <Profile />
      },
      {
        name: "sign out",
        route: "/signout",
        component: <SignOut/>
      },
    ]
  }
];

export default routes;
