import MyProjects from "../components/MyProjects"
import Payments from "../components/Payments"
import Profile from "../components/Profile"

export const MYACCOUNT_TABS = [
    {
        "value": 0,
        "label": "My Projects",
        "url": "projects",
        "component": <MyProjects/>
    },
    {
        "value": 1,
        "label": "Payments",
        "url": "payments",
        "component": <Payments/>
    },
    {
        "value": 2,
        "label": "Profile",
        "url": "profile",
        "component": <Profile/>
    },
]