
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
// import RotatingCard from "examples/Cards/RotatingCard";
// import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.webp";
import bgBack from "assets/images/rotating-card-bg-back.webp";
import RotatingCard from "components/Cards/RotatingCard";
import RotatingCardFront from "components/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "components/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                   Get Your Customized Quote Today
                  </>
                }
                description="Explore our straightforward approach, from initial request to final approval. We guide you through every stage, ensuring clarity and success."
              />
              <RotatingCardBack
                image={bgBack}
                title="Get Quotes"
                description="Reach out for a tailored quote today. Our experts are here to offer competitive pricing and personalized advice."
                action={{
                  type: "internal",
                  route: "/new-project",
                  label: "start now",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Comprehensive Thesis Writing Services"
                  description="Get expert assistance for your thesis from concept to completion, ensuring quality and originality in every section"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Research Paper Writing and Editing"
                  description="Our experts craft high-impact research papers tailored to your specific requirements, ensuring adherence to journal standards."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="remove_red_eye"
                  title="Content Analysis and Data Interpretation"
                  description="We provide detailed content analysis and data interpretation to support your research findings with accuracy and clarity."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Plagiarism-Free Guarantee and Revisions"
                  description="We ensure all work is free from plagiarism and offer a revision period to address any concerns."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
