import { combineReducers } from "redux"
import userInfoReducer from "./userInfo/reducer"
import projectDetailsReducer from "./projectDetails/reducer"
import paymentReducer from "./payment/reducer"
import contactUsState from "./contactUs/reducer"


const rootReducer = combineReducers({
    userInfoState: userInfoReducer,
    projectDetailsState: projectDetailsReducer,
    paymentState: paymentReducer,
    contactUsState: contactUsState,
})

export default rootReducer