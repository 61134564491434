import { getContactUsAPI } from "api/contactus.api";


export const sendContactUsQuery = (name, email, query) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'SET_CONTACT_US_STATE',
                payload: 'LOADING'
            });
            const data = await getContactUsAPI(name, email, query);
            if (data.status === 200) {
                dispatch({
                    type: 'SET_CONTACT_US_STATE',
                    payload: 'COMPLETE'
                });
            } else {
                dispatch({
                    type: 'SET_CONTACT_US_STATE',
                    payload: 'FAILED'
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_CONTACT_US_STATE',
                payload: 'FAILED',
            })
        }
    };
}