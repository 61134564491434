
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import FeaturesOption from "../components/FeaturesOption";

function Features() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}  component="h2">
              Explore Our Proposal Writing Features
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Discover about the outstanding qualities of our proposal
              writing service, which has been painstakingly created to
              satisfy the various demands of academics.
            </MKTypography>
            <MKTypography
              component="a"
              href="/new-project"
              variant="button"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              Get quotes now
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <FeaturesOption
                icon="mediation"
                content={
                  <>
                    Our service guarantees that your project gets off to a good start by creating
                    persuasive and painstakingly documented proposals based on your research objectives.
                  </>
                }
              />
              <FeaturesOption
                icon="settings_overscan"
                content={
                  <>
                    We value your satisfaction. Enjoy the peace of mind knowing that we offer 
                    unlimited revisions, ensuring your document meets your expectations.
                  </>
                }
              />
              <FeaturesOption
                icon="token"
                content={
                  <>
                    Our structured research outlines provide a roadmap for your study, 
                    ensuring every aspect is thoughtfully organized and executed.
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;
