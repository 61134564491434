

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MKDropdown
import MKDropdownRoot from "components/MKDropdown/MKDropdownRoot";
import MKInput from "components/MKInput";
import { Autocomplete } from "@mui/material";

const MKDropdown = forwardRef(({ error, success, disabled, options, label, ...rest }, ref) => (
  <Autocomplete 
    {...rest} 
    ref={ref}
    options={options}
    renderInput= {(params)=><MKInput
         {...params}
        error={error}
        success={success}
        label={label}
        textAlign={"center"}
    />
    }
  />
));

// Setting default values for the props of MKDropdown
MKDropdown.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  options: []
};

// Typechecking props for the MKDropdown
MKDropdown.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
};

export default MKDropdown;
