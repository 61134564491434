export const setAuthTokenCookie = (token, expirationDays=30) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    const cookieValue = encodeURIComponent(token) + '; expires=' + expirationDate.toUTCString() + '; path=/';
    document.cookie = 'authToken=' + cookieValue;
};

export const checkAuthTokenCookie = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('authToken=')) {
            const authToken = decodeURIComponent(cookie.substring('authToken='.length));
            // Perform any necessary checks or actions with the authToken
            return authToken;
        }
    }

    return undefined; // Token cookie not found
};

export const deleteAuthTokenCookie=()=> {
    document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }