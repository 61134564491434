// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import image from "assets/images/examples/blog2.webp";
import { sendContactUsQuery } from "redux/contactUs/action";
import { useDispatch } from "react-redux";

import { useEffect, useState } from "react";

function Cta() {

  const [isEmailOk, setIsEmailOk] = useState(true);
  const [email, setEmail] = useState('');
  
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault()
    setIsEmailOk(email.length > 0);
    if (email.length === 0 ) return;
    dispatch(sendContactUsQuery('', email, ''));
    setEmail('');
    // props.handleClose();
  }

  return (
    <MKBox component="section" py={8}>
      <MKBox bgColor="grey-100" py={12} px={{ xs: 3, lg: 0 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6} ml="auto">
            <MKTypography variant="h4" mb={1} component="h3">
            Still unsure about your next steps?
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
            Schedule a call with us now for personalized guidance and support.
            Our experts are ready to address your concerns and help you make 
            informed decisions about your academic journey
            </MKTypography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <MKInput label="Email Here" fullWidth 
                error={!isEmailOk} value={email} onChange={(e) => setEmail(e.target.value)}/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MKButton variant="gradient" color="warning" sx={{ height: "100%" }} onClick={handleSubmit}>
                  Schedule a Callback
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4} position="relative">
            {/* <MKBox
              component="img"
              src={image}
              alt="image"
              maxWidth="18.75rem"
              width="100%"
              borderRadius="lg"
              shadow="xl"
              mt={-24}
              display={{ xs: "none", lg: "block" }}
            /> */}
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default Cta;