import { useReducer } from "react";


export const newProjectPackage = {
    packageName: '',
    packagePrice: 0,
    packageFeatures: [],
    packageId: -1,
}

export const newProjectValidationInitialState = {
    pursuingField: true,
    domainOfResearch: true,
    topicOfResearch: true,
    documentNeeded: true,
    submissionDate: true,
    objective: true,
    proposedTitle: true,
    typeOfResearch: true,
    programOfStudy: true,
    researchQuestions: true,
    method: true,
    literatureReview: true,
    additionalRequirements: true,
    specifiedJournal: true,
    journalFormat: true,
    typeOfPlagiarismRemoval: true,
    percentageOfSimilartyIndex: true,
    selectedPackage: true,
}

export const newProjectInitialState = {
    activeTab: 0,
    pursuingField: '',
    domainOfResearch: '',
    topicOfResearch: '',
    documentNeeded: {
        "RESEARCH_PROPOSAL": false,
        "RESEARCH_REPORT": false,
        "LITERATURE_REVIEW": false,
        "THESIS_OR_DISSERTATION": false,
        "CONFERENCE_PAPER_INFO_LABEL": false,
        "JOURNAL_ARTICLE": false,
        "ETHICS_APPLICATION_PLAG": false,
        "DATA_MANAGEMENT_PLAN": false,
        "SURVEY_INSTRUMENT": false,
    },
    submissionDate: '',

    objective: '',
    proposedTitle: '',
    typeOfResearch: '',
    programOfStudy: '',
    researchQuestions: '',
    method: '',
    literatureReview: '',
    additionalRequirements: '',
    specifiedJournal: '',
    journalFormat: '',
    typeOfPlagiarismRemoval: '',
    percentageOfSimilartyIndex: '',
    selectedPackage: newProjectPackage,
    isEditingProject: false,

    validationState: newProjectValidationInitialState
};

export const newProjectReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_STEP':
            return { ...state, activeTab: action.payload };
        case 'SET_PURSUING_FIELD':
            return { ...state, pursuingField: action.payload };
        case 'SET_DOMAIN_OF_RESEARCH':
            return { ...state, domainOfResearch: action.payload };
        case 'SET_TOPIC_OF_RESEARCH':
            return { ...state, topicOfResearch: action.payload };
        case 'TOGGLE_DOCUMENT_NEEDED': {
            let newState = { ...state };
            newState.documentNeeded[action.payload] = !state.documentNeeded[action.payload];
            return newState;
        }
        case 'SET_SUBMISSION_DATE':
            return { ...state, submissionDate: action.payload };
        case 'SET_OBJECTIVE':
            return { ...state, objective: action.payload };
        case 'SET_PROPOSED_TITLE':
            return { ...state, proposedTitle: action.payload };
        case 'SET_TYPE_OF_RESEARCH':
            return { ...state, typeOfResearch: action.payload };
        case 'SET_PROGRAM_OF_STUDY':
            return { ...state, programOfStudy: action.payload };
        case 'SET_RESEARCH_QUESTIONS':
            return { ...state, researchQuestions: action.payload };
        case 'SET_METHOD':
            return { ...state, method: action.payload };
        case 'SET_LITERATURE_REVIEW':
            return { ...state, literatureReview: action.payload };
        case 'SET_ADDITIONAL_REQUIREMENTS':
            return { ...state, additionalRequirements: action.payload };
        case 'SET_SPECIFIED_JOURNAL':
            return { ...state, specifiedJournal: action.payload };
        case 'SET_JOURNAL_FORMAT':
            return { ...state, journalFormat: action.payload };
        case 'SET_TYPE_OF_PLAGIARISM_REMOVAL':
            return { ...state, typeOfPlagiarismRemoval: action.payload };
        case 'SET_PERCENTAGE_OF_SIMILARITY_INDEX':
            return { ...state, percentageOfSimilartyIndex: action.payload };
        case 'SET_SELECTED_PACKAGE':
            return { ...state, selectedPackage: action.payload };
        case 'SET_VALIDATION_STATE':
            return { ...state, validationState: action.payload };
        case 'SET_EDITING_PROJECT':
            return { ...state, isEditingProject: action.payload };
        default:
            throw new Error('Unknown action type');
    }
};


