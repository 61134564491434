

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import TncCollapse from "./components/TncCollapse";

// Routes
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function TermsAndCondition() {

  const userInfoState = useSelector(state => state.userInfoState);

  return (
    <>
      <Helmet>
        <title>Innovus Analytics | Terms and Conditions | Expert Writing services in India | Terms and Conditions </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/terms/" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>

        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white" component="h2">
                    Terms And Conditions
                  </MKTypography>
                  {/* <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: Sept 07 2021
                  </MKTypography> */}
                </MKBox>
                <MKBox p={6}>
                  {/* <MKTypography variant="h5" my={3}>
                    Basics
                  </MKTypography> */}
                  <TncCollapse
                    title="Intelluctual Property"
                  >
                    The content of this website is for your general information and use only.
                    It is subject to change without notice.
                    <br />
                    <br />
                    This website contains material which is owned by or licensed to us. This
                    material includes, but is not limited to, the design, layout, look, appearance,
                    and graphics. Reproduction is prohibited without our express written consent.
                  </TncCollapse>
                  <TncCollapse
                    title="Privacy Policy"
                  >
                    Your use of this website is also governed by our Privacy Policy. Please
                    review our Privacy Policy to understand our practices.
                  </TncCollapse>
                  <TncCollapse
                    title="User Conduct"
                  >
                    You agree not to use our website for any unlawful purpose or in a way
                    that could impair the proper functioning of the site.
                  </TncCollapse>

                  <TncCollapse
                    title="Limitation of Liability"
                  >
                    We shall not be liable for any direct, indirect, incidental, special,
                    or consequential damages arising out of the use or inability to use our
                    website.
                  </TncCollapse>
                  <TncCollapse
                    title="Changes of Terms"
                  >
                    We reserve the right to revise these terms and conditions at any time.
                    By using this website, you agree to be bound by the current version of
                    these terms.
                    <br />
                    <br />
                    If you have any questions about our Terms and Conditions, please contact
                    us at contactus@innovusanalytics.com.
                  </TncCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TermsAndCondition;
