

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function Privacy() {
  const userInfoState = useSelector(state => state.userInfoState);
  return (
    <>
      <Helmet>
        <title>Innovus Analytics | Privacy Policy | Expert Writing services in India | Privacy Policy </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/privacy-policy/" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white" component="h2">
                    Privacy & Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: Jan 07 2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    At Innovus Analytics, accessible{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://www.innovusanalytics.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      here
                    </MKTypography>
                    , one of our main priorities is the privacy of our visitors. This Privacy Policy
                    document contains types of information that is collected and recorded by Website
                    Name and how we use it.
                    <br />
                    <br />
                    If you have additional questions or require more information about our Privacy
                    Policy, do not hesitate to contact us through email at hello@innovusanalytics.com
                    <br />
                    <br />
                    This privacy policy applies only to our online activities and is valid for
                    visitors to our website with regards to the information that they shared and/or
                    collect in{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://www.innovusanalytics.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      Innovus Analytics
                    </MKTypography>
                    . This policy is not applicable to any information collected offline or via
                    channels other than this website.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Managing Our Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Unless otherwise stated, Innovus Analytics and/or its licensors own the intellectual
                    property rights for all material on Innovus Analytics. All intellectual property
                    rights are reserved. You may access this from Innovus Analytics for your own personal
                    use subjected to restrictions set in these terms and conditions.
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    You must not:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Republish material from Innovus Analytics
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Sell, rent or sub-license material from Innovus Analytics
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Reproduce, duplicate or copy material from Innovus Analytics
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Redistribute content from Innovus Analytics
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    Creative Tim reserves the right to monitor all Comments and to remove any
                    Comments which can be considered inappropriate, offensive or causes breach of
                    these Terms and Conditions.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Information We Collect
                  </MKTypography>
                  <MKTypography variant="body2" color="text" >
                    <MKTypography variant="body2" color="text" fontWeight="regular" component="span">
                      Personal Information:{" "}
                    </MKTypography>
                    We may collect personally identifiable information that you provide to us,
                    including but not limited to your name, email address, and any other information you choose to share.
                  </MKTypography>
                  <MKTypography variant="body2" color="text" >
                    <MKTypography variant="body2" color="text" fontWeight="regular" component="span">
                      Non-Personal Information:{" "}
                    </MKTypography>
                    We may collect non-personal information about you, such as your IP address, browser type,
                    the type of device you are using, and other anonymous usage data.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Security
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    We implement appropriate security measures to protect your personal information from unauthorized access,
                    alteration, disclosure, or destruction. Our website may contain links to third-party websites or services
                    that are not owned or controlled by us. We have no control over and assume no responsibility for the content,
                    privacy policies, or practices of any third-party sites or services.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Changes to This Privacy Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    We reserve the right to update or change our Privacy Policy at any time. Your continued use of the{" "}
                    <MKTypography
                      variant="body2"
                      color="text"
                      component="a"
                      href="https://www.innovusanalytics.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                      fontWeight="regular"
                    >
                      site
                    </MKTypography>
                    {" "}after we post any modifications to the Privacy Policy on
                    <MKTypography
                      variant="body2"
                      color="text"
                      component="a"
                      href="https://www.innovusanalytics.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                      fontWeight="regular"
                    >
                      {" "}this page{" "}
                    </MKTypography>
                    will constitute your acknowledgment
                    of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
