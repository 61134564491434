import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { numberToCurrency } from "utility/currency";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentOrder } from "redux/payment/action";

// Material Kit 2 PRO React components


function PostPaymentModal({ show, handleClose, amount, description, orderId, isPaymentPassed }) {

    const dispatch = useDispatch();
    const userInfoState = useSelector(state => state.userInfoState);
    const handleSubmit = (e) => {
            handleClose();
    }

    const closeModal=()=>{
        handleClose();
    }



    return (

        <Modal open={show} onClose={closeModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={200}>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alginItems="center" p={2}>
                        <MKTypography variant="h5">
                            {isPaymentPassed ? "Payment Successful" : "Payment Failed" }
                        </MKTypography>
                        {/* <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={closeModal} /> */}
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox px={3} py={2}>
                        <MKTypography variant="body2" color="secondary" fontWeight="bold">
                            Payment of amount {numberToCurrency(amount)} {isPaymentPassed ? " has been successfully processed." : " failed. In case your money has been debited, it will be credited to your bank account within 5-7 business days."}
                        </MKTypography>


                        <Grid container mt={2}>
                            
                            <Grid item xs={4} display={"flex"}>
                                
                            </Grid>
                        </Grid>
                    </MKBox>

                    <Divider sx={{ my: 0 }} />
                    <MKBox display="flex" justifyContent="right" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={closeModal}>
                            Close
                        </MKButton>
                        
                    </MKBox>
                </MKBox>
            </Slide>
        </Modal>
        //   </Container>
        // </MKBox>
    );
}

PostPaymentModal.defaultProps = {
    show: false,
    handleClose: () => { },
    amount: 0,
    description: "",
    orderId: "",
    isPaymentPassed: false
}

PostPaymentModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    amount: PropTypes.number,
    description: PropTypes.string,
    orderId: PropTypes.string,
    isPaymentPassed: PropTypes.bool,
}

export default PostPaymentModal;