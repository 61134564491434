
import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import Home from "pages/LandingPages/Home";
import ContactUs from "pages/Support/ContactUs";
import AboutUs from "pages/Company/AboutUs";
import Privacy from "pages/Support/Privacy";
import Faq from "pages/Support/Faq";
import TermsAndCondition from "pages/Support/TermsAndCondition";
import Refund from "pages/Support/Refund";
import SignUp from "pages/Authentication/SignUp";
import ResetPassword from "pages/Authentication/ResetPassword";
import PageNotFound from "pages/Error";
import ThesisWritingService from "pages/LandingPages/ThesisWritingService";
import FairUsagePolicy from "pages/Support/FairUsage";
import ResearchPaperService from "pages/LandingPages/ResearchPaper";
import NewProject from "pages/NewProject";
import SelectPackage from "pages/ChoosePackage";
import SingleBlog from "pages/Blogs/SingleBlog";
import Blogs from "pages/Blogs/Home";
import Blog1ResearchTopicAndEvloution from "pages/Blogs/SingleBlog/Blog1ResearchTopic";
import Blog2ExploringQuestionnaire from "pages/Blogs/SingleBlog/Blog2ExploringQuestionnaire";
import Blog3VerifyingHypothesis from "pages/Blogs/SingleBlog/Bolg3VerifyingHypothesis";
import Blog4HowToWriteThesis from "pages/Blogs/SingleBlog/Blog4HowToWriteThesis";
import Blog5HowToWriteResearch from "pages/Blogs/SingleBlog/Blog5HowToWriteResearch";
import Blog6EmipiricalAnalysis from "pages/Blogs/SingleBlog/Blog6EmipiricalAnalysis";
import Project from "pages/MyAccount/Project";
import Dashboard from "pages/MyAccount/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthTokenCookie } from "utility/cookie";
import { getUserInfo } from "redux/userInfo/action";
import ContentAnalysisService from "pages/LandingPages/ContentAnalysisService";

const AuthenticatedRoute = ({ element, ...rest }) => {
  let urlPathname = window.location.pathname
  const url = window.location.href
  if (url.indexOf("redirect=") > -1) {
    const redirectTo = url.substring(url.indexOf("redirect=") + "redirect=".length)
    urlPathname = redirectTo;
  }
  const userInfoState = useSelector(state => state.userInfoState);
  return (
    <>
      {userInfoState.isUserLoggedIn ? element :
        <Navigate to={"/signin?redirect=" + urlPathname} />
      }
    </>
  );
};

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const dispatch = useDispatch();
  const cookieAuthToken = checkAuthTokenCookie();
  useEffect(() => {
    if (cookieAuthToken !== '' && cookieAuthToken !== undefined) {
      dispatch(getUserInfo(cookieAuthToken));
    }
    else if (process.env.REACT_APP_COOKIE_AUTH_TOKEN_LOCAL_DEBUGGING !== ''){
      dispatch(getUserInfo(process.env.REACT_APP_COOKIE_AUTH_TOKEN_LOCAL_DEBUGGING));
    }
  }, [])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms" element={<TermsAndCondition />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/fair-usage-policy" element={<FairUsagePolicy />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="/pagenotfound" element={<PageNotFound />} />
        <Route path="/thesis-writing-services" element={<ThesisWritingService />} />
        <Route path="/research-paper-services" element={<ResearchPaperService />} />
        <Route path="/content-analysis-services" element={<ContentAnalysisService />} />
        

        <Route path="/new-project" element={
          <AuthenticatedRoute element={<NewProject />} />
        } />

        <Route path="/select-package" element={<SelectPackage />} />

        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/research-topic-are-evolving-post-internet-revolution" element={<Blog1ResearchTopicAndEvloution />} />
        <Route path="/blog/exploring-questionnaire-reliability-testing-beyond-cronbachs-alpha" element={<Blog2ExploringQuestionnaire />} />
        <Route path="/blog/verifying-hypothesis-with-multiple-methods" element={<Blog3VerifyingHypothesis />} />
        <Route path="/blog/how-to-write-thesis" element={<Blog4HowToWriteThesis />} />
        <Route path="/blog/how-to-write-research-papers" element={<Blog5HowToWriteResearch />} />
        <Route path="/blog/how-to-do-emipirical-analysis" element={<Blog6EmipiricalAnalysis />} />


        <Route path="/project/:id" element={
          <AuthenticatedRoute element={<Project />} />
        } />
        <Route path="/projects/" element={
          <AuthenticatedRoute element={<Dashboard />} />
        } />
        <Route path="/profile/" element={
          <AuthenticatedRoute element={<Dashboard />} />
        } />
        <Route path="/payments/"  element={
          <AuthenticatedRoute element={<Dashboard />} />
        } />


        <Route path="*" element={<Navigate to="/pagenotfound" />} />
      </Routes>
    </ThemeProvider>
  );
}
