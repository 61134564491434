// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKDropdown from "components/MKDropdown";
import MKDatePicker from "components/MKDatePicker";
import Button from "assets/theme/components/button";
import FormControlLabel from "assets/theme/components/form/formControlLabel";
import SelectableButtonWithCheckbox from "../components/SelectableButtonWithCheckbox";

function BasicInformation({ state, dispatch, handleNext }) {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox width="100%" component="form" method="post" autoComplete="off">
            <MKBox p={3}>
              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <MKTypography variant="h6" color="dark" fontWeight="regular" >
                    What are you pursuing?
                  </MKTypography>
                </Grid>
                {/* <Grid item xs={12}>
                    <MKDropdown 
                    options={["High School","Bachelors","Masters (PG)", "PhD", ]}
                    label={"Choose your program"}
                    size={"medium"}
                    />
                </Grid> */}
                <Grid item xs={12} mb={2} >
                  <MKInput label="Let us know whether you're pursuing a Master's degree, a PhD, or are currently in high school" fullWidth
                    value={state.pursuingField}
                    onChange={(evt) => { dispatch({ type: 'SET_PURSUING_FIELD', payload: evt.target.value }) }}
                    error={!state.validationState.pursuingField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MKTypography variant="h6" color="dark" fontWeight="regular" >
                    Domain of research
                  </MKTypography>
                </Grid>
                <Grid item xs={12} mb={2} >
                  <MKInput label="Add your domain of research" fullWidth
                    value={state.domainOfResearch}
                    onChange={(evt) => { dispatch({ type: 'SET_DOMAIN_OF_RESEARCH', payload: evt.target.value }) }}
                    error={!state.validationState.domainOfResearch}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MKTypography variant="h6" color="dark" fontWeight="regular" >
                    Specify your Topic
                  </MKTypography>
                </Grid>
                <Grid item xs={12} mb={2} >
                  <MKInput label="The specific research topic, or at least the broad concept of the topic" multiline fullWidth rows={3}
                    value={state.topicOfResearch}
                    onChange={(evt) => { dispatch({ type: 'SET_TOPIC_OF_RESEARCH', payload: evt.target.value }) }}
                    error={!state.validationState.topicOfResearch}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MKTypography variant="h6" color="dark" fontWeight="regular" >
                    Type of document
                  </MKTypography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Research proposal"}
                    description={"A document that outlines the research project's objectives, methods, and expected outcomes"}
                    checked={state.documentNeeded["RESEARCH_PROPOSAL"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "RESEARCH_PROPOSAL" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Research report"}
                    description={"A document that summarizes the research findings, methodology, and conclusions"}
                    checked={state.documentNeeded["RESEARCH_REPORT"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "RESEARCH_REPORT" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Literature review"}
                    description={"A critical evaluation of existing literature on a specific topic"}
                    checked={state.documentNeeded["LITERATURE_REVIEW"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "LITERATURE_REVIEW" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Thesis or dissertation"}
                    description={"A comprehensive document that presents the research findings, methodology, and conclusions of a student's research project"}
                    checked={state.documentNeeded["THESIS_OR_DISSERTATION"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "THESIS_OR_DISSERTATION" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Conference paper"}
                    description={"A document that summarizes the research project and is presented at a conference or academic meeting"}
                    checked={state.documentNeeded["CONFERENCE_PAPER_INFO_LABEL"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "CONFERENCE_PAPER_INFO_LABEL" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Journal article"}
                    description={"A document that presents the research findings and is published in a peer-reviewed academic journal"}
                    checked={state.documentNeeded["JOURNAL_ARTICLE"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "JOURNAL_ARTICLE" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Ethics application"}
                    description={"A document that outlines the ethical considerations of the research project, including participant consent, privacy, and confidentiality"}
                    checked={state.documentNeeded["ETHICS_APPLICATION_PLAG"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "ETHICS_APPLICATION_PLAG" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectableButtonWithCheckbox
                    label={"Data management plan"}
                    description={"A document that outlines how the research data will be collected, stored, and shared"}
                    checked={state.documentNeeded["DATA_MANAGEMENT_PLAN"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "DATA_MANAGEMENT_PLAN" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>
                <Grid item xs={12} lg={6} mb={2}>
                  <SelectableButtonWithCheckbox
                    label={"Survey instrument"}
                    description={"A document that outlines the questions to be asked in a survey"}
                    checked={state.documentNeeded["SURVEY_INSTRUMENT"]}
                    onClick={() => { dispatch({ type: 'TOGGLE_DOCUMENT_NEEDED', payload: "SURVEY_INSTRUMENT" }) }}
                    error={!state.validationState.documentNeeded}
                  />
                </Grid>


                <Grid item xs={12}>
                  <MKTypography variant="h6" color="dark" fontWeight="regular" >
                    Submission date
                  </MKTypography>
                </Grid>
                <Grid item xs={12} mb={2} >
                  {/* <MKInput label="Add your domain of research" fullWidth /> */}
                  <MKDatePicker color="dark" fullWidth
                    value={state.submissionDate}
                    options={{ minDate: new Date()}} 
                    onChange={(dayJsObj) => {
                      dispatch({ type: 'SET_SUBMISSION_DATE', payload: dayJsObj[0] });
                    }}
                  />
                </Grid>


              </Grid>
              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKButton type="button" variant="gradient" color="info"
                  // onClick={() => dispatch({ type: 'SET_ACTIVE_STEP', payload: 1 })}
                  onClick={handleNext}
                >
                  Submit
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BasicInformation;