

import { useEffect, useReducer, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components


// Routes
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { profileReducer, profileInitialState } from "./profile.reducer";
import { setUserDetail } from "redux/userInfo/action";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import SkeletonProfile from "./components/SkeletonProfile";
import { getUserDetail } from "redux/userInfo/action";

function Profile() {
    const userInfoState = useSelector(state => state.userInfoState);
    const reduxDispatch = useDispatch();

    const [state, dispatch] = useReducer(profileReducer, {
        ...profileInitialState,
        name: userInfoState.userInfo?.userName || '',
        mobile: userInfoState.userInfo?.userMobile || '',
        address: {
            ...profileInitialState.address,
            addressLine1: userInfoState.userInfo?.userAddress?.addressLine1 || '',
            locality: userInfoState.userInfo?.userAddress?.locality || '',
            city: userInfoState.userInfo?.userAddress?.city || '',
            pincode: userInfoState.userInfo?.userAddress?.pincode || '',
            state: userInfoState.userInfo?.userAddress?.state || '',
            country: userInfoState.userInfo?.userAddress?.country || '',
        }
    });

    useEffect(() => {
        dispatch({
            type: 'SET_PROFILE_DATA', payload: {
                name: userInfoState.userInfo?.userName || '',
                mobile: userInfoState.userInfo?.userMobile || '',
                address: {
                    addressLine1: userInfoState.userInfo?.userAddress?.addressLine1 || '',
                    locality: userInfoState.userInfo?.userAddress?.locality || '',
                    city: userInfoState.userInfo?.userAddress?.city || '',
                    state: userInfoState.userInfo?.userAddress?.state || '',
                    country: userInfoState.userInfo?.userAddress?.country || '',
                    pincode: userInfoState.userInfo?.userAddress?.pincode || '',
                },
            }
        })
    }, [userInfoState.userInfo])


    const onSave = () => {
        let isValid = false;
        let validationState = {
            name: state.name.length > 0,
            mobile: state.mobile.length >= 10,
            address: {
                addressLine1: state.address.addressLine1.length > 0,
                locality: true,
                city: state.address.city.length > 0,
                state: state.address.state.length > 0,
                country: state.address.country.length > 0,
                pincode: state.address.pincode.length === 6,
            }
        }

        isValid = validationState.name && validationState.mobile && validationState.address.addressLine1
            && validationState.address.locality && validationState.address.city && validationState.address.state
            && validationState.address.country && validationState.address.pincode;

        let isDifferentFromLocalData = state.name !== userInfoState.userInfo?.userName ||
            state.mobile !== userInfoState.userInfo?.userMobile ||
            state.address.addressLine1 !== userInfoState.userInfo?.userAddress.addressLine1 ||
            state.address.locality !== userInfoState.userInfo?.userAddress.locality ||
            state.address.city !== userInfoState.userInfo?.userAddress.city ||
            state.address.state !== userInfoState.userInfo?.userAddress.state ||
            state.address.country !== userInfoState.userInfo?.userAddress.country ||
            state.address.pincode !== userInfoState.userInfo?.userAddress.pincode;

        dispatch({ type: 'SET_VALIDATION_STATE', payload: validationState });
        if (isValid && isDifferentFromLocalData && userInfoState.userInfo !== undefined) {
            // Save to api
            reduxDispatch(setUserDetail(userInfoState.userInfo?.authToken, {
                name: state.name,
                address: state.address.addressLine1,
                locality: state.address.locality,
                city: state.address.city,
                state: state.address.state,
                zip: state.address.pincode,
                phone: state.mobile,
                country: state.address.country
            }));
        }
    }

    useEffect(() => {
        if (userInfoState.userInfo !== undefined &&
            userInfoState.userInfo.authToken.length > 0 &&
            userInfoState.userDetailsLoadingState.isLoaded === false)
            reduxDispatch(getUserDetail(userInfoState.userInfo.authToken));
    }, [])


    return (
        <>
            {userInfoState.userDetailsLoadingState.isLoading ?
                <SkeletonProfile />
                :

                <Grid container p={3} spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <MKInput type="text" label="Full Name" fullWidth
                            value={state.name}
                            onChange={(evt) => { dispatch({ type: 'SET_FULL_NAME', payload: evt.target.value }) }}
                            error={!state.validationState.name}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKInput type="text" label="Mobile" fullWidth
                            value={state.mobile}
                            error={!state.validationState.mobile}
                            onChange={(evt) => { dispatch({ type: 'SET_MOBILE', payload: evt.target.value }) }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MKInput type="text" label="Address(House No, Building, Street, Area)" fullWidth
                            value={state.address.addressLine1}
                            error={!state.validationState.address.addressLine1}
                            onChange={(evt) => { dispatch({ type: 'SET_ADDRESS_LINE1', payload: evt.target.value }) }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <MKInput type="text" label="Locality/ Town" fullWidth
                            value={state.address.locality}
                            onChange={(evt) => { dispatch({ type: 'SET_LOCALITY', payload: evt.target.value }) }}
                            error={!state.validationState.address.locality}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKInput type="text" label="City/ District" fullWidth
                            value={state.address.city}
                            error={!state.validationState.address.city}
                            onChange={(evt) => { dispatch({ type: 'SET_CITY', payload: evt.target.value }) }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <MKInput type="text" label="Pincode" fullWidth
                            value={state.address.pincode}
                            onChange={(evt) => { dispatch({ type: 'SET_PINCODE', payload: evt.target.value }) }}
                            error={!state.validationState.address.pincode}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MKInput type="text" label="State" fullWidth
                            value={state.address.state}
                            onChange={(evt) => { dispatch({ type: 'SET_STATE', payload: evt.target.value }) }}
                            error={!state.validationState.address.state}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKInput type="text" label="Country" fullWidth
                            value={state.address.country}
                            error={!state.validationState.address.country}
                            onChange={(evt) => { dispatch({ type: 'SET_COUNTRY', payload: evt.target.value }) }}
                        />

                    </Grid >
                    <Grid item xs={12} >
                        <MKButton variant="outlined" color="info" onClick={onSave} fullWidth>
                            Save Changes
                        </MKButton>
                    </Grid>

                </Grid>
            }
        </>

    )
}

export default Profile;