
// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import GoogleSocial from "./googleSocial";

function Socials() {
  return (
    <MKBox display="flex" justifyContent="center">
      {/* <MKButton variant="outlined" color="light" sx={{ py: 1.75, px: 1.25 }}>
        <svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(3.000000, 3.000000)" fillRule="nonzero">
              <circle fill="#3C5A9A" cx="29.5091719" cy="29.4927506" r="29.4882047" />
              <path
                d="M39.0974944,9.05587273 L32.5651312,9.05587273 C28.6886088,9.05587273 24.3768224,10.6862851 24.3768224,16.3054653 C24.395747,18.2634019 24.3768224,20.1385313 24.3768224,22.2488655 L19.8922122,22.2488655 L19.8922122,29.3852113 L24.5156022,29.3852113 L24.5156022,49.9295284 L33.0113092,49.9295284 L33.0113092,29.2496356 L38.6187742,29.2496356 L39.1261316,22.2288395 L32.8649196,22.2288395 C32.8649196,22.2288395 32.8789377,19.1056932 32.8649196,18.1987181 C32.8649196,15.9781412 35.1755132,16.1053059 35.3144932,16.1053059 C36.4140178,16.1053059 38.5518876,16.1085101 39.1006986,16.1053059 L39.1006986,9.05587273 L39.0974944,9.05587273 L39.0974944,9.05587273 Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </svg>
      </MKButton>
      <MKBox px={1}>
      </MKBox> */}
      <GoogleSocial />
    </MKBox>
  );
}

export default Socials;
