// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/logo_mini.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Innovus Analytics",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      // link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      // link: "https://twitter.com/creativetim",
    },
    {
      icon: <InstagramIcon />,
      // link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/about-us" },
        { name: "contact us", href: "/contact-us" },
        // { name: "freebies", href: "https://www.creative-tim.com/templates/free" },
        // { name: "premium tools", href: "https://www.creative-tim.com/templates/premium" },
        { name: "blog", href: "/blog" },
      ],
    },
    // {
    //   name: "resources",
    //   items: [
    //     // { name: "thesis writing services", href: "/thesis-writing-services" },
    //     // { name: "research paper services", href: "/research-paper-services" },
    //     // { name: "illustrations", href: "https://iradesign.io/" },
    //     // { name: "bits & snippets", href: "https://www.creative-tim.com/bits" },
    //     // { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },
    //   ],
    // },
    {
      name: "services",
      items: [
        { name: "thesis writing services", href: "/thesis-writing-services" },
        { name: "thesis writing services in india", href: "/thesis-writing-services" },
        { name: "phd thesis writing services", href: "/thesis-writing-services" },
        { name: "dissertation writing services", href: "/thesis-writing-services" },
        { name: "dissertation help online", href: "/thesis-writing-services" },
        { name: "online thesis writing", href: "/thesis-writing-services" },
        // { name: "illustrations", href: "https://iradesign.io/" },
        // { name: "bits & snippets", href: "https://www.creative-tim.com/bits" },
        // { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },
      ],
    },
    // {
    //   name: "help & support",
    //   items: [

    //     { name: "FAQs", href: "/faq" },
    //     // { name: "custom development", href: "https://services.creative-tim.com/" },
    //     // { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" },
    //   ],
    // },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/terms" },
        { name: "privacy policy", href: "/privacy-policy" },
        { name: "refund policy", href: "/refund-policy" },
        { name: "fair usage policy", href: "/fair-usage-policy" },
        { name: "FAQs", href: "/faq"},
      ],
    },
    {
      name: "Blogs",
      items: [

        { name: "Research topics and evolution", href: "/blog/research-topic-are-evolving-post-internet-revolution" },
        { name: "Unveiling questionnaire reliability", href: "/blog/exploring-questionnaire-reliability-testing-beyond-cronbachs-alpha" },
        { name: "Enhancing research findigns", href: "/blog/verifying-hypothesis-with-multiple-methods" },
        { name: "How to write thesis", href: "/blog/how-to-write-thesis" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Innovus Analytics.
    </MKTypography>
  ),
};
