import axios from "axios";

export const fetchProjectDetailAPI = (projectId, authentication) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/project');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.post(url.toString(), { projectId }, {headers});
}

export const fetchProjectsAPI = (authentication) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/project');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.get(url.toString(), { headers });
}

export const saveProjectDetailAPI = (data, authentication) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/project');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.post(url.toString(), data, { headers });
}

export const updateProjectDetailAPI = (projectId, data, authentication) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/project');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.put(url.toString(), data, { headers });
}


