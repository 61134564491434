export const DOCUMENT_NEEDED_MAP = {
    
}

export const PROJECT_TNC=[
    'Terms and Conditions are subject to change without prior notice. Please check this page regularly to stay updated.',
    'The project is a part of the academic curriculum and is built for learning purposes only. The project is not intended to be used for any commercial purpose.',
    'The project is built using open-source software and is available for free. The project is not intended to be sold.',
'You are free to use the project for any purpose, including commercial purposes, without any prior permission.',
]

export const PROJECT_STATES=[
    "Draft",
    "Submitted for review",
    "Review in progress",
    "Change requested",
    "Approved",
    "In progress",
    "Completed",
    "Cancelled",
    "Refunded"
]