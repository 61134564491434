

import { useEffect, useState } from "react";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes

import { LinearProgress, Skeleton } from "@mui/material";



function SkeletonProject() {


  return (
    <>
        <MKBox
            variant="gradient"
            bgColor="dark"
            borderRadius="lg"
            coloredShadow="dark"
            p={3}
            mt={-3}
            mx={2}
        >
            {/* <Skeleton variant="text" sx={{ fontSize: '3rem', bgcolor: 'white' }} /> */}
            <MKTypography variant="h3" color="white" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" width="90%">
                Loading Project ...
            </MKTypography>

        </MKBox>
        <MKBox p={6}>


            <Skeleton variant="text" sx={{ fontSize: '2rem' }}  />
            <Skeleton variant="rounded" height={100}  />

            <Skeleton variant="text" sx={{ fontSize: '2rem', mt:"2rem" }}  />
            <Skeleton variant="rounded" height={200}  />

        </MKBox>
    </>
  );
}

export default SkeletonProject;
