

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes

import { Grid, LinearProgress, Skeleton } from "@mui/material";
import { PROJECT_TNC } from "../defs";
import ProjectCollapse from "../ProjectCollapse";
import { numberToCurrency } from "utility/currency";
import _ from "lodash";
import { getDate_DDMMYYYY } from "utility/date";
import MKButton from "components/MKButton";
import PaymentModal from "../PaymentModal";
import PostPaymentModal from "../PostPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";


function PaidAmountBreakup({ project }) {
    const [showPaymentModal, togglePaymentModel] = useState(false);
    const [showPostPaymentModal, togglePostPaymentModel] = useState(false);
    const [isPaymentPassed, setIsPaymentPassed] = useState(false);

    const paymentReducer = useSelector(state=>state.paymentState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (paymentReducer.paymentState === "COMPLETE"){
            setIsPaymentPassed(true);
            togglePostPaymentModel(true);
        }
        else if (paymentReducer.paymentState === "FAILED"){
            setIsPaymentPassed(false);
            togglePostPaymentModel(true);
        }
    }, [paymentReducer.paymentState])

    const postPaymentModalClose = ()=>{
        togglePostPaymentModel(!showPostPaymentModal);
        dispatch({
            type: 'SET_PAYMENT_STATE',
            payload: {
                state: "STARTED"
            }
        });

        // To reload project
        dispatch({
            type: 'RESET_PROJECT_DETAILS',
        });

        // to reset payment detail
        dispatch({
            type: 'RESET_PAYMENT_DETAIL',
        })
    }
    

    return (
        <>
            {(showPostPaymentModal && isPaymentPassed) ? 
            
                <Helmet>
                    <script>
                    {`
                        gtag('event', 'conversion', {
                            'send_to': 'AW-16480734626/5HBpCP2qmssZEKKb0LI9',
                            'transaction_id': ''
                        });
                    `}
                    </script>
                </Helmet>
                :
                <></>
            }
            <MKBox
                variant="gradient"
                bgColor="none"
                borderRadius="lg"
                coloredShadow="dark"
                px={2}
            >
                <ProjectCollapse
                    title="Paid Amount"
                >
                    <Grid container>
                        {project.payments && project.payments.map((amount, index) =>
                            <>
                                <Grid item xs={9}>
                                    <MKTypography variant="body2" color="dark" fontWeight="regular">
                                        Payment {index + 1}
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text" fontWeight="regular">
                                        {" "}on {getDate_DDMMYYYY(amount.createdAt)}
                                    </MKTypography>

                                </Grid>
                                <Grid item xs={3} >
                                    <MKBox display="flex" justifyContent="end">
                                        <MKTypography variant="body2" color="dark" fontWeight="regular">
                                            {numberToCurrency(amount.amount)}
                                        </MKTypography>
                                    </MKBox>
                                </Grid>
                            </>

                        )}
                    </Grid>
                    {/* Total paid amount */}
                    <Grid container>
                        {project.payments && project.payments.length>0 &&
                            <Grid item xs={12} mb={1}>
                                <MKBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    py={1}
                                    sx={{
                                        // cursor: "pointer",
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    }}
                                >
                                </MKBox>
                            </Grid>
                        }
                        <Grid item xs={9}>
                            <MKTypography variant="body2" color="dark" fontWeight="bold">
                                Total Amount Paid
                            </MKTypography>
                        </Grid>
                        <Grid item xs={3} >
                            <MKBox display="flex" justifyContent="end">
                                <MKTypography variant="body2" color="dark" fontWeight="regular">
                                    {numberToCurrency(_.sumBy(project.payments, 'amount'))}
                                </MKTypography>
                            </MKBox>

                        </Grid>
                    </Grid>

                    {/* Amount due */}
                    <Grid container>
                        <Grid item xs={12} mb={1}>
                            <MKBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                py={1}
                                sx={{
                                    // cursor: "pointer",
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                }}
                            >
                            </MKBox>
                        </Grid>
                        <Grid item xs={9}>
                            <MKTypography variant="body2" color="dark" fontWeight="bold">
                                Amount Due
                            </MKTypography>
                        </Grid>
                        <Grid item xs={3} >
                            <MKBox display="flex" justifyContent="end">
                                <MKTypography variant="body2" color="dark" fontWeight="regular">
                                    {numberToCurrency((_.sumBy(project.prices, 'price')) - _.sumBy(project.payments, 'amount'))}
                                </MKTypography>
                            </MKBox>
                        </Grid>
                    </Grid>

                    {/* Pay now */}
                    {(_.sumBy(project.prices, 'price')) - _.sumBy(project.payments, 'amount') > 0 &&
                        <MKButton variant="gradient" color="info" size="large" sx={{
                            width: "100%",
                            mt: "1.5rem"
                        }} onClick={()=>togglePaymentModel(true)} >
                            Pay now
                        </MKButton>
                    }
                </ProjectCollapse>
            </MKBox >
            <PaymentModal
                show={showPaymentModal}
                handleClose={() => { togglePaymentModel(!showPaymentModal) }}
                amountDue={(_.sumBy(project.prices, 'price')) - _.sumBy(project.payments, 'amount')}
                description={'Payment for project ' + project.projectId}
                projectId={project.projectId}
                isAdvancePaymentPending={true}         // Keeping it true for time being
            />
            <PostPaymentModal
                show= {showPostPaymentModal}
                handleClose={postPaymentModalClose}
                amount= {paymentReducer.amount / 100}
                description= ""
                orderId={paymentReducer.orderId}
                isPaymentPassed= {isPaymentPassed}
            />
        </>
    );
}


PaidAmountBreakup.defaultProps = {
    project: {}
}

PaidAmountBreakup.propTypes = {
    project: PropTypes.objectOf(PropTypes.string),
}

export default PaidAmountBreakup;
