import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Sections components
// import BaseLayout from "layouts/sections/components/BaseLayout";
// import View from "layouts/sections/components/View";

// HelpCenter page components
import FaqCollapse from "components/FaqCollapse";


function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (

    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="How do I get quote for a project?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              Simply visit our website and fill out the
              <MKTypography
                variant="h6"
                fontWeight="normal"
                color="dark"
                component="a"
                href="/new-project"
                sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
              >
                {" "}project inquiry form{" "}
              </MKTypography>
              with details
              about your requirements. Our team will then assess your needs
              and provide you with a personalized quote tailored to your project specifications.
              Alternatively, you can schedule a consultation call with our experts to discuss your
              project in detail and receive a quote accordingly.

            </FaqCollapse>
            <FaqCollapse
              title="How can i make the payment?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              Making a payment is easy and secure. Once you have finalized your order, you will
              be directed to our payment portal, where you can choose from a variety of payment
              options, including credit/debit cards, UPI, and bank transfers. Follow the instructions
              provided to complete the payment process securely.
            </FaqCollapse>
            <FaqCollapse
              title="How long does it take to complete a project?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              The time required to complete a project varies depending on factors such as the length
              and complexity of the project. We provide estimated timelines based on your specific
              requirements, and our team works diligently to meet deadlines.
            </FaqCollapse>
            <FaqCollapse
              title="How much do thesis writing services cost?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Thesis writing service prices vary, and they depend on two things. The first one is the writing you will choose when placing your order. The second one is the deadline you will select for the service to be completed. The closer the date, the higher the price. By choosing a date that is not as close, the price will be significantly lower.
            </FaqCollapse>
            <FaqCollapse
              title="What is the 30 days validity?"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              We offer a 30-day validity period (in Advanced and Prioirty package) for revisions
              to ensure that your thesis aligns with your initial project requirements. This allows
              ample time for necessary adjustments and ensures your complete satisfaction with
              the final deliverable.
            </FaqCollapse>
            <FaqCollapse
              title="Will you use pre-written content in my research paper?"
              open={collapse === 6}
              onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
            >
              We ensure that every paper ordered through our service is custom written and 100% unique. We do not use any pre-written material to complete your order. When you choose a writer to write a research paper, you can be sure he will provide unique content that will meet all of the requirements provided. All papers are created from scratch and depend on your instructions. Upon receipt of the paper, you can check it for plagiarism before paying.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;