

import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import ProjectCollapse from "./components/ProjectCollapse";

// Routes
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProjectDetail } from "redux/projectDetails/action";
import { LinearProgress, Skeleton } from "@mui/material";
import SkeletonProject from "./components/SkeletonProject";
import _ from 'lodash';
import ProjectMain from "./components/ProjectMain";
import ProjectNotFound from "./components/ProjectNotFound";
import { getProjects } from "redux/projectDetails/action";
import PostPaymentModal from "./components/PostPaymentModal";



function Project() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userInfoState = useSelector(state => state.userInfoState);
  const projectDetailsState = useSelector(state => state.projectDetailsState);

  useEffect(() => {
    // dispatch(fetchProjectDetail(id, userInfoState.userInfo.authToken, ));

    if (userInfoState.userInfo !== undefined &&
      userInfoState.userInfo.authToken.length > 0) {
      if (  projectDetailsState.projectDetailsLoadingState.isLoaded === false)
        dispatch(getProjects(userInfoState.userInfo.authToken))
      // else if (projectDetailsState.projectDetails.length === 0 &&
      //     projectDetailsState.projectDetailsLoadingState.isLoaded === false &&
      //     projectDetailsState.projectDetailsLoadingState.isLoadingFailed === false)
      //     dispatch(getProjects(userInfoState.userInfo.authToken))
    }

  }, []);

  useEffect(() => {
    if (userInfoState.userInfo !== undefined &&
        userInfoState.userInfo.authToken.length > 0 &&
        projectDetailsState.projectDetails.length === 0 &&
        projectDetailsState.projectDetailsLoadingState.isLoaded === false &&
        projectDetailsState.projectDetailsLoadingState.isLoadingFailed === false)
        dispatch(getProjects(userInfoState.userInfo.authToken))
    }, [projectDetailsState.projectDetails])

  return (
    <>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>

        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                {projectDetailsState.projectDetailsLoadingState.isLoading ?
                  <SkeletonProject />
                  :
                  _.filter(projectDetailsState.projectDetails, { projectId: id }).length > 0 &&
                    projectDetailsState.projectDetails !== undefined
                    // && !projectDetailsState.projectDetailsLoadingState.isLoadingFailed 
                    ?
                    <ProjectMain
                      project={_.filter(projectDetailsState.projectDetails, { projectId: id })[0]}
                    />
                    :
                    <ProjectNotFound
                      id={id}
                    />
                }
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Project;
