
export const isJournalRelatedProjectSelected = (documentNeeded) => {
    if (documentNeeded.JOURNAL_ARTICLE || documentNeeded.CONFERENCE_PAPER_INFO_LABEL || documentNeeded.LITERATURE_REVIEW) {
        return true
    }
}

export const isResearchRelatedProjectSelected = (documentNeeded) => {
    if (documentNeeded.RESEARCH_PROPOSAL || documentNeeded.RESEARCH_REPORT || documentNeeded.THESIS_OR_DISSERTATION) {
        return true
    }
}

export const isEthicsRelatedProjectSelected = (documentNeeded) => {
    if (documentNeeded.ETHICS_APPLICATION_PLAG) {
        return true
    }
}