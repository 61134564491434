import rootReducer from "./rootReducer"
import { configureStore } from "@reduxjs/toolkit";


const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {}
            }
        })
    // preloadedState: preloadedState
});

export default store

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

