

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "./components/FaqCollapse";

// Routes
import routes from "routes";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function Faq() {
  const [collapse, setCollapse] = useState(false);
  const userInfoState = useSelector(state => state.userInfoState);

  return (
    <>
      <Helmet>
        <title>Innovus Analytics | faq | Expert Writing services in India | FAQ </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/faq/" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>

        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white" component="h2">
                    FAQ
                  </MKTypography>
                  {/* <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: Sept 07 2021
                  </MKTypography> */}
                </MKBox>
                <MKBox p={6}>
                  <MKTypography variant="h5" my={3} component="h4">
                    Basics
                  </MKTypography>
                  <FaqCollapse
                    title="How do I order?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    To place an order, simply visit our website and fill out the project form
                    with your requirements. Once submitted, our team will review your request
                    and get in touch with you to discuss the details further.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How can i make the payment?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    Making a payment is easy and secure. Once you have finalized your order,
                    you will be directed to our payment portal, where you can choose from a variety
                    of payment options, including credit/debit cards, UPI, and bank transfers.
                    Follow the instructions provided to complete the payment process securely.
                    If you encounter any issues or have specific payment preferences, feel free
                    to reach out to our customer support team for assistance.
                  </FaqCollapse>
                  <FaqCollapse
                    title="What payment methods do you accept?"
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    We accept various payment methods, including credit/debit cards, UPI,
                    and bank transfers. You can choose the option that is most convenient for
                    you during the checkout process.
                  </FaqCollapse>

                  <FaqCollapse
                    title="How long does it take to complete a project?"
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    The time required to complete a project varies depending on factors such
                    as the length and complexity of the project. We provide estimated timelines
                    based on your specific requirements, and our team works diligently to meet deadlines.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How do I communicate with the writer assigned to my project?"
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    After placing your order, you will be assigned a dedicated writer who
                    will be in direct communication with you throughout the writing process.
                    You can communicate with them via our secure messaging system on our platform.
                  </FaqCollapse>

                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Account & Settings
                  </MKTypography>
                  <FaqCollapse
                    title="How do I change my password?"
                    open={collapse === 6}
                    onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                  >
                    To change your password, simply navigate to the "Account Settings" section
                    of your profile. Within this section, you should find an option to update
                    your password. Click on it, and you will be prompted to enter your
                    current password followed by your new desired password. Once you've
                    entered this information and confirmed the changes, your password will
                    be updated successfully.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How can I delete my account?"
                    open={collapse === 7}
                    onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                  >
                    If you wish to delete your account, please contact our customer support team
                    directly. They will assist you in the account deletion process and ensure that
                    your request is handled securely and in accordance with our privacy policies.
                    You may reach out to our support team via email and they
                    will guide you through the necessary steps to delete your account permanently.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How to reset my password?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    You can easily do so by visiting the login page of our website or application. Look
                    for the "Forgot Password" option and click on it. You will then be prompted to
                    enter the email address associated with your account. After submitting your email
                    address, you will receive further instructions via email on how to reset your password.
                    Follow the provided link or instructions to set a new password for your account.
                  </FaqCollapse>
                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Projects
                  </MKTypography>
                  <FaqCollapse
                    title="What measures do you take to ensure the originality of the thesis?"
                    open={collapse === 10}
                    onClick={() => (collapse === 10 ? setCollapse(false) : setCollapse(10))}
                  >
                    We take plagiarism very seriously. Our writers are instructed to produce
                    original content for every thesis, and we use plagiarism detection tools
                    to verify the authenticity of the work before delivering it to you.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How can I track the progress of my thesis/project?"
                    open={collapse === 11}
                    onClick={() => (collapse === 11 ? setCollapse(false) : setCollapse(11))}
                  >
                    You can track the progress of your project by logging into your account
                    dashboard. There, you'll find detailed updates on the status of your
                    project, including milestones achieved, deadlines met, and any pending
                    tasks.
                  </FaqCollapse>
                  <FaqCollapse
                    title="What measures are in place to ensure timely completion of my project?"
                    open={collapse === 12}
                    onClick={() => (collapse === 12 ? setCollapse(false) : setCollapse(12))}
                  >
                    Our platform employs a robust project management system that includes regular
                    communication with your assigned freelancer, milestone tracking, and deadline
                    enforcement. Additionally, we provide you with tools to monitor progress and
                    address any concerns promptly.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Can I request modifications or revisions during the project development phase?"
                    open={collapse === 13}
                    onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
                  >
                    Yes, absolutely! We encourage open communication throughout the project development
                    phase. If you have any feedback or require modifications to be made, you can communicate
                    directly with your freelancer or reach out to our support team for assistance.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How do you ensure quality assurance and adherence to project requirements?"
                    open={collapse === 14}
                    onClick={() => (collapse === 14 ? setCollapse(false) : setCollapse(14))}
                  >
                    Quality assurance is paramount to us. We carefully vet all freelancers on our platform
                    and provide them with detailed project requirements. Additionally, we conduct regular
                    reviews and checks to ensure that the work delivered meets your expectations and adheres
                    to the project specifications. If you have any concerns about quality, please let us
                    know, and we will take appropriate action to resolve them.
                  </FaqCollapse>
                  <FaqCollapse
                    title="What is the validity period for the revisions provided?"
                    open={collapse === 15}
                    onClick={() => (collapse === 15 ? setCollapse(false) : setCollapse(15))}
                  >
                    We offer a 30-day validity period (in Advanced and Prioirty package) for revisions to ensure that your thesis aligns
                    with your initial project requirements. This allows ample time for necessary adjustments
                    and ensures your complete satisfaction with the final deliverable.
                  </FaqCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default Faq;
