import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.webp";
import { useDispatch, useSelector } from "react-redux";
import { sendContactUsQuery } from "redux/contactUs/action";
import { Helmet } from "react-helmet";

function ContactUs() {
  const userInfoState = useSelector(state => state.userInfoState);
  const contactUsState = useSelector(state => state.contactUsState);
  const [isEmailOk, setIsEmailOk] = useState(true);
  const [email, setEmail] = useState('');
  const [isNameOk, setIsNameOk] = useState(true);
  const [name, setName] = useState('');
  const [isQueryOk, setIsQueryOk] = useState(true);
  const [query, setQuery] = useState('');
  
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault()
    setIsEmailOk(email.length > 0);
    setIsNameOk(name.length > 0);
    setIsQueryOk(query.length > 0);

    if (email.length === 0 || name.length === 0) return;
    dispatch(sendContactUsQuery(name, email, query));
    // props.handleClose();
  }

  return (
    <>
      <Helmet>
        <title>Innovus Analytics | Contact Us | Expert Writing services in India | Contact Us form </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/contact-us/" />
      </Helmet>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          visibleIfLoggedIn={userInfoState.isUserLoggedIn}
          action={userInfoState.isUserLoggedIn ? undefined :{
            type: "internal",
            route: "/signup",
            label: "sign up",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} xl={6}>
          <MKBox
            display={{ xs: "none", xl: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", xl: 6 }}
          mr={{ xs: "auto", xl: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={1}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white" component="h2">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
              Reach out to us with any queries or feedback. We're here to assist you. Please email
                hello@innovusanalytics.com or contact using our contact form.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={!isNameOk} value={name} onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={!isEmailOk} value={email} onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="What can we help you?"
                      placeholder="Describe your problem in at least 250 characters"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      error={!isQueryOk} value={query} onChange={(e) => setQuery(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info" onClick={handleSubmit}
                  disabled={contactUsState.loadingState == 'LOADING' || contactUsState.loadingState == 'COMPLETE'}>
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
