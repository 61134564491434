import { Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";
import { numberToCurrency } from "utility/currency";
import { getDate_DDMMYYYY } from "utility/date";


function SinglePayment({ payment }) {

    return (
        <>
            <MKBox
                variant="gradient"
                bgColor="none"
                borderRadius="lg"
                coloredShadow="light"
                width="100%"
                height="fit-content"
                p={1.5}
                mb={3}
            >
                <Grid container gap={1}>
                    <Grid container mb={1.5}>
                        <MKTypography variant="body2" color="dark" fontWeight="bold">
                            Payment ID:&nbsp;
                        </MKTypography>
                        <MKTypography variant="body2" color="dark" fontWeight="regular">
                            {payment.paymentId}
                        </MKTypography>
                    </Grid>

                    <MKTypography variant="body2" color="dark" fontWeight="bold">
                        Initiated on:
                    </MKTypography>
                    <MKTypography variant="body2" color="dark" fontWeight="regular">
                        {getDate_DDMMYYYY(payment.createdAt)} &nbsp;&#8226;&nbsp;&nbsp;
                    </MKTypography>

                    <MKTypography variant="body2" color="dark" fontWeight="bold">
                        Amount:
                    </MKTypography>
                    <MKTypography variant="body2" color="dark" fontWeight="regular">
                        {numberToCurrency(payment.amount)}&nbsp;&#8226;&nbsp;&nbsp;
                    </MKTypography>


                    <MKTypography variant="body2" color="dark" fontWeight="bold">
                        Status
                    </MKTypography>

                    <MKBox
                        variant="contained"
                        bgColor={payment.paymentStatus === "COMPLETED" ? "info": "warning"}
                        borderRadius="xxl"
                        coloredShadow="dark"
                        px={1}
                        mx={2}
                    >
                        <MKTypography variant="body2" color="white" fontWeight="regular" textTransform="capitalize">
                            {payment.paymentStatus}
                        </MKTypography>
                    </MKBox>


                </Grid>
            </MKBox>

        </>

    )
}

SinglePayment.defaultProps = {
    payment: {}
}

SinglePayment.propTypes = {
    payment: PropTypes.objectOf(PropTypes.string),
}

export default SinglePayment;