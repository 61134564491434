

import { useEffect, useState } from "react";
import ProjectCollapse from "../ProjectCollapse";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";
import { numberToCurrency } from "utility/currency";

// @mui material components

// Material Kit 2 PRO React components


// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes




function PackageDetail({project}) {


  return (
    <>
        <ProjectCollapse
        title="Package Details"
        >

        <MKTypography variant="body2" color="dark" fontWeight="regular">
            Package Name
        </MKTypography>
        {project.packageDetails.packageName}
        <br/>
        <br/>

        {project.packageDetails?.packageDescription &&
            <>
            <MKTypography variant="body2" color="dark" fontWeight="regular">
                Package Desscription
            </MKTypography>
            {project.packageDetails?.packageDescription}
            <br/>
            <br/>
            </>

        }

        <MKTypography variant="body2" color="dark" fontWeight="regular">
            Package Cost
        </MKTypography>
            {project.packageDetails.packagePrice > 0 ? 
                numberToCurrency(project.packageDetails.packagePrice)
            :
                "Submitted for review"
            }

        <br/>
        <br/>

        {project.packageDetails.packageFeatures && <>
            <MKTypography variant="body2" color="dark" fontWeight="regular">
                Package features
            </MKTypography>
            {project.packageDetails.packageFeatures.map((feature) =>
                        <>&#8226;&nbsp;&nbsp;{feature}&nbsp;&nbsp;</>
                )}

        </>}

        </ProjectCollapse>
    </>
  );
}

PackageDetail.defaultProps = {
    project: {}
}

PackageDetail.propTypes = {
    project: PropTypes.objectOf(PropTypes.string),
}

export default PackageDetail;
