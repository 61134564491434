

import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components


// Routes
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import EmptyProject from "./components/EmptyProject";
import SingleProject from "./components/SingleProject";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import SkeletonProject from "./components/SkeletonProject";
import { getProjects } from "redux/projectDetails/action";

function MyProjects() {
    const projectDetailsState = useSelector(state => state.projectDetailsState)
    const userInfoState = useSelector(state => state.userInfoState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfoState.userInfo !== undefined &&
            userInfoState.userInfo.authToken.length > 0 &&
            projectDetailsState.projectDetailsLoadingState.isLoaded === false &&
            projectDetailsState.projectDetailsLoadingState.isLoadingFailed === false)
            dispatch(getProjects(userInfoState.userInfo.authToken))
    }, [])


    useEffect(() => {
        if (userInfoState.userInfo !== undefined &&
            userInfoState.userInfo.authToken.length > 0 &&
            projectDetailsState.projectDetails.length === 0 &&
            projectDetailsState.projectDetailsLoadingState.isLoaded === false &&
            projectDetailsState.projectDetailsLoadingState.isLoadingFailed === false)
            dispatch(getProjects(userInfoState.userInfo.authToken))
    }, [projectDetailsState.projectDetails])

    return (
        <>
            {projectDetailsState.projectDetails === undefined || projectDetailsState.projectDetails.length === 0
                // ||projectDetailsState.projectDetails.filter(project => project.statusCode !== 0).length === 0 
                ?
                <EmptyProject /> :

                projectDetailsState.projectDetailsLoadingState.isLoading ?
                    <Grid container mx={2}>
                        {[1, 2, 3].map((val, index) => <SkeletonProject key={index} />)}
                    </Grid>
                    :
                    <Grid container mx={3}>
                        {projectDetailsState.projectDetails
                            .map((project, index) =>
                                <SingleProject
                                    key={index}
                                    project={project}
                                />)
                        }
                    </Grid>

            }

        </>

    )
}

export default MyProjects;