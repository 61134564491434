import { Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";



function EmptyPayment() {
    const navigate = useNavigate();

    return (
        <>
            <MKBox
                // variant="gradient"
                bgColor="none"
                // borderRadius="lg"
                // coloredShadow="light"
                width="100%"
                height="auto"
                p={2}
                mb={3}
            >
                <Grid container justifyContent={"center"} alignItems={"center"}>
                    <Grid container justifyContent={"center"} mb={2} >
                        <MKTypography variant="body2" color="dark" fontWeight="bold"  >
                            No payment record
                        </MKTypography>
                    </Grid>
                    <MKButton variant="outlined" color="info" onClick={()=>navigate("/projects")} >
                        Go to my projects
                    </MKButton>
                </Grid>
            </MKBox>

        </>

    )
}

export default EmptyPayment;