

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes

import { Grid, LinearProgress, Skeleton } from "@mui/material";
import { PROJECT_TNC } from "../defs";
import ProjectCollapse from "../ProjectCollapse";
import { numberToCurrency } from "utility/currency";
import _ from "lodash";


function PriceBreakup({ project }) {
    return (
        <>
            <MKBox
                variant="gradient"
                bgColor="none"
                borderRadius="lg"
                coloredShadow="dark"
                px={2}
            >
                <ProjectCollapse
                    title="Price Breakup"
                >

                    <Grid container>
                        {project.prices.map((price, index) =>
                            <>
                                <Grid item xs={9}>
                                    <MKTypography variant="body2" color="dark" fontWeight="regular">
                                        {price.label}
                                    </MKTypography>
                                </Grid>
                                <Grid item xs={3} >
                                    <MKBox display="flex" justifyContent="end">
                                        <MKTypography variant="body2" color="dark" fontWeight="regular">
                                            {numberToCurrency(price.price)}
                                        </MKTypography>
                                    </MKBox>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} mb={1}>
                            <MKBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                py={1}
                                sx={{
                                    // cursor: "pointer",
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                }}
                            >
                            </MKBox>
                        </Grid>
                        <Grid item xs={9}>
                            <MKTypography variant="body2" color="dark" fontWeight="bold">
                                Total Price
                            </MKTypography>
                        </Grid>
                        <Grid item xs={3} >
                            <MKBox display="flex" justifyContent="end">
                                <MKTypography variant="body2" color="dark" fontWeight="regular">
                                    {numberToCurrency(_.sumBy(project.prices, 'price'))}
                                </MKTypography>
                            </MKBox>

                        </Grid>
                    </Grid>
                </ProjectCollapse>
            </MKBox >
        </>
    );
}


PriceBreakup.defaultProps = {
    project: {}
}

PriceBreakup.propTypes = {
    project: PropTypes.objectOf(PropTypes.string),
}

export default PriceBreakup;
