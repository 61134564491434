import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { numberToCurrency } from "utility/currency";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentOrder } from "redux/payment/action";

// Material Kit 2 PRO React components


function PaymentModal({ show, handleClose, amountDue, description, projectId, isAdvancePaymentPending }) {
    const [isFullPay, setIsFullPay] = useState(true);
    const [amount, setAmount] = useState(amountDue)

    const dispatch = useDispatch();
    const userInfoState = useSelector(state => state.userInfoState);
    const handleSubmit = (e) => {

        if (!isNaN(Number(amount)) && Number(amount) <= amountDue &&
            userInfoState.userInfo) {
            dispatch(createPaymentOrder(
                userInfoState.userInfo?.authToken,
                amount * 100, 'INR',
                '',
                userInfoState.userInfo.userName,
                userInfoState.userInfo.userEmail,
                userInfoState.userInfo.userMobile || '',
                description,
                projectId));

            handleClose();
        }
    }

    const closeModal=()=>{
        setIsFullPay(true);
        handleClose();
    }

    const handleRadioChange = (event) => {
        setIsFullPay((event.target).value === "payfull");
        setAmount(amountDue);
    };

    const setPartialAmount = (event) => {
        if (!isNaN(Number((event.target).value)))
            setAmount(Number((event.target).value));
    };


    return (

        <Modal open={show} onClose={closeModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={200}>
                <MKBox
                    position="relative"
                    width="400px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">
                            Payment plan
                        </MKTypography>
                        <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={closeModal} />
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox px={3} py={2}>
                        <MKTypography variant="body2" color="secondary" fontWeight="bold">
                            Please select a payment plan
                        </MKTypography>


                    <Grid container mt={2}>
                        <Grid item xs={8}>

                            <RadioGroup
                                aria-labelledby="payment-type"
                                defaultValue="payfull"
                                name="payment-type"
                                onChange={handleRadioChange}

                            >
                                <FormControlLabel value="payfull" control={<Radio />} label="Pay Full" />
                                {isAdvancePaymentPending &&
                                    <FormControlLabel value="paypartial" control={<Radio />} label="Pay Partial" sx={{
                                        paddingTop: '0.5rem',
                                        paddingBottom: '1rem'
                                    }} />
                                }
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={4} display={"flex"}>
                            {!isFullPay &&
                                <TextField id="partial-amount" type="text" label="Amount" variant="outlined" margin='normal'
                                    sx={{
                                        alignSelf: 'flex-end',
                                        // justifySelf: 'center'
                                    }} placeholder='Please enter amount'
                                    value={amount} onChange={setPartialAmount} />
                            }
                        </Grid>
                    </Grid>
                    </MKBox>

                    <Divider sx={{ my: 0 }} />
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={closeModal}>
                            Cancel
                        </MKButton>
                        <MKButton variant="gradient" color="info" disabled={ (amount > amountDue ||
                            amount < 1 )} onClick={handleSubmit}>
                            Pay now {numberToCurrency(amount)}
                        </MKButton>
                    </MKBox>
                </MKBox>
            </Slide>
        </Modal>
        //   </Container>
        // </MKBox>
    );
}

PaymentModal.defaultProps = {
    show: false,
    handleClose: () => { },
    amountDue: 0,
    description: "",
    projectId: "",
    isAdvancePaymentPending: false
}

PaymentModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    amountDue: PropTypes.number,
    description: PropTypes.string,
    projectId: PropTypes.string,
    isAdvancePaymentPending: PropTypes.bool
}

export default PaymentModal;