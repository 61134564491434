import axios from "axios";

export const createPaymentOrderAPI = (authentication,
    amount,
    currency,
    receipt,
    projectId) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/payment');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.post(url.toString(), {
        amount, currency, receipt, projectId
    }, { headers });
}


export const getPaymentSuccessAPI = (data) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/payment/success');
    return axios.post(url.toString(), data);
}

export const getPaymentsAPI = (authentication) => {
    const url = new URL(process.env.REACT_APP_URL + 'api/payment');
    const headers = {
        'Authorization': `Bearer ${authentication}`
    };
    return axios.get(url.toString(), { headers });
}