export const getDate_DDMMYYYY=(d, delimeter)=>{
    if (delimeter== undefined)
        delimeter = "-";
    let date = new Date(d);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero indexed, so add 1
    const year = date.getFullYear();
  
    // Pad single-digit day and month with leading zero if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
  
    // Construct the formatted date string in "dd-mm-yyyy" format
    const formattedDate = `${formattedDay}${delimeter}${formattedMonth}${delimeter}${year}`;
    return formattedDate;
}