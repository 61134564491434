
// type Action =
//     | { type: 'SET_FULL_NAME'; payload: string }
//     | { type: 'SET_MOBILE'; payload: string }
//     | { type: 'SET_ADDRESS_LINE1'; payload: string }
//     | { type: 'SET_LOCALITY'; payload: string }
//     | { type: 'SET_CITY'; payload: string }
//     | { type: 'SET_STATE'; payload: string }
//     | { type: 'SET_COUNTRY'; payload: string }
//     | { type: 'SET_PINCODE'; payload: string }
//     | { type: 'SET_PROFILE_DATA'; payload: IProfileUIState }
//     | { type: 'SET_VALIDATION_STATE'; payload: IDashboardProfileValidationUIState };

export const profileValidationInitialState = {
    name: true,
    mobile: true,
    address: {
        addressLine1: true,
        locality: true,
        city: true,
        state: true,
        country: true,
        pincode: true,
    }
}

export const profileInitialState = {
    name: '',
    mobile: '',
    address: {
        addressLine1: '',
        locality: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
    },
    validationState: profileValidationInitialState
}

export const profileReducer = (state = profileInitialState, action) => {
    switch (action.type) {
        case 'SET_FULL_NAME':
            return { ...state, name: action.payload }
        case 'SET_MOBILE':
            return { ...state, mobile: action.payload }
        case 'SET_ADDRESS_LINE1':
            return {
                ...state, address: {
                    ...state.address,
                    addressLine1: action.payload
                }
            }
        case 'SET_LOCALITY':
            return {
                ...state, address: {
                    ...state.address,
                    locality: action.payload
                }
            }
        case 'SET_CITY':
            return {
                ...state, address: {
                    ...state.address,
                    city: action.payload
                }
            }
        case 'SET_STATE':
            return {
                ...state, address: {
                    ...state.address,
                    state: action.payload
                }
            }
        case 'SET_COUNTRY':
            return {
                ...state, address: {
                    ...state.address,
                    country: action.payload
                }
            }
        case 'SET_PINCODE':
            return {
                ...state, address: {
                    ...state.address,
                    pincode: action.payload
                }
            }
        case 'SET_PROFILE_DATA':
            return {
                ...state,
                name: action.payload.name,
                mobile: action.payload.mobile,
                address: {
                    addressLine1: action.payload.address.addressLine1,
                    locality: action.payload.address.locality,
                    city: action.payload.address.city,
                    state: action.payload.address.state,
                    country: action.payload.address.country,
                    pincode: action.payload.address.pincode,
                },
            }
        case 'SET_VALIDATION_STATE':
            return {
                ...state,
                validationState: action.payload
            }

        default:
            return state;
    }
}

