

import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import ProjectCollapse from "./components/ProjectCollapse";

// Routes
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { AppBar, Tab, Tabs } from "@mui/material";
import { MYACCOUNT_PATHNAME_INDEX, MYACCOUNT_TABS, MYACCOUNT_URLS } from "./defs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Dashboard() {
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const lastPathSegment = pathname.split('/').pop();
  // let localActiveTab = MYACCOUNT_PATHNAME_INDEX[Object.keys(MYACCOUNT_PATHNAME_INDEX).filter(key=> lastPathSegment.indexOf(key) == 0)[0]]
  let localActiveTab = MYACCOUNT_TABS.filter(tab=> lastPathSegment.indexOf(tab["url"]) ==0)[0].value
  const [activeTab, setActiveTab] = useState(localActiveTab);


  useEffect(() => {
    if (activeTab !== localActiveTab)
      setActiveTab(localActiveTab)
  }, [localActiveTab])
  
  const userInfoState = useSelector(state => state.userInfoState);
  return (
    <>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined :{
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>

        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" width="90%">
                    My Projects 
                  </MKTypography>

                  
                </MKBox>
                <MKBox p={4} display="flex" >
                  <Grid container item justifyContent="center" xs={false} lg={3} mx="auto" sx={{
                    display: { xs: "none", lg: "block" }
                  }}>
                    <AppBar position="static">
                      <Tabs value={activeTab} orientation="vertical" >
                        {/* <Tab label="My Projects" sx={{p:1.5}}/>
                        <Tab label="Payments" sx={{p:1.5}}/>
                        <Tab label="Profile" sx={{p:1.5}}/> */}
                        {MYACCOUNT_TABS.map(tab=>
                          <Tab label={tab.label} value={tab.value} sx={{p:1.5}}
                            onClick={()=>navigate("/"+tab.url)}
                          />)}
                      </Tabs>
                    </AppBar>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} lg={9} mx="auto">
                    {MYACCOUNT_TABS.filter(tab=>tab.value === activeTab)[0].component}
                  </Grid>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Dashboard;
