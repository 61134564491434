

import { useEffect, useState } from "react";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes

import { LinearProgress, Skeleton } from "@mui/material";



function SkeletonProject() {


    return (
        <>
            <MKBox
                variant="gradient"
                bgColor="none"
                borderRadius="lg"
                coloredShadow="light"
                width="100%"
                height="auto"
                p={2}
                mb={3}
            >
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            </MKBox>
        </>
    );
}

export default SkeletonProject;
