

import { useEffect, useState } from "react";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes

import { Grid, LinearProgress, Skeleton } from "@mui/material";



function SkeletonProfile() {


    return (
        <>

            <Grid container p={3} spacing={1}>
                <Grid item xs={12} lg={6}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Grid>

                <Grid item xs={6} lg={6}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Grid>

                <Grid item xs={3} md={3} lg={3}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />

                </Grid >
                {/* <Grid item xs={12} >
                        <MKButton variant="outlined" color="info" onClick={onSave} fullWidth>
                            Save Changes
                        </MKButton>
                    </Grid> */}

            </Grid>
        </>
    );
}

export default SkeletonProfile;
