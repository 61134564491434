

import { useEffect, useState } from "react";
import ProjectCollapse from "../ProjectCollapse";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";
import { getDate_DDMMYYYY } from "utility/date";
import _ from 'lodash'

// @mui material components

// Material Kit 2 PRO React components


// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes




function DetailedInfo({project}) {

  return (
    <>
        <ProjectCollapse
        title="Detailed Information"
        >

        {project.objective && project.objective.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Objective
                </MKTypography>
                {_.startCase(project.objective)}
                <br/>
                <br/>
            </>
        }

        {project.proposedTitle && project.proposedTitle.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Proposed Title
                </MKTypography>
                        {_.startCase(project.proposedTitle)}
                <br/>
                <br/>
            </>
        }

        {project.typeOfResearch && project.typeOfResearch.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Type of research
                </MKTypography>
                        {_.startCase(project.typeOfResearch)}
                    <br/>
                    <br/>
            </>
        }

        <MKTypography variant="body2" color="dark" fontWeight="regular">
            Submission Date
        </MKTypography>
            {getDate_DDMMYYYY(project.submissionDate)}

        <br/>
        <br/>
        {project.programOfStudy && project.programOfStudy.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                   Program of study
                </MKTypography>
                        {_.startCase(project.programOfStudy)}
                <br/>
                <br/>
            </>
        }

        {project.researchQuestions && project.researchQuestions.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Research Questions
                </MKTypography>
                {(project.researchQuestions || []).map((item, index) =>
                        <>&#8226;&nbsp;&nbsp; {_.startCase(item)}</>
                )}
                <br/>
                <br/>
            </>
        }

        {project.method && project.method.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Method
                </MKTypography>
                        {_.startCase(project.method)}
                <br/>
                <br/>
            </>
        }

        {project.literatureReview && project.literatureReview.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Literature Review
                </MKTypography>
                        {_.startCase(project.literatureReview)}
                <br/>
                <br/>
            </>
        }

        {project.additionalRequirements && project.additionalRequirements.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Additional Requirements
                </MKTypography>
                        {_.startCase(project.additionalRequirements)}
                <br/>
                <br/>
            </>
        }

        {project.specifiedJournal && project.specifiedJournal.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Specified Journal
                </MKTypography>
                        {_.startCase(project.specifiedJournal)}
                    <br/>
                <br/>
            </>
        }

        {project.journalFormat && project.journalFormat.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Journal Format
                </MKTypography>
                        {_.startCase(project.journalFormat)}
                <br/>
                <br/>
            </>
        }

        {project.typeOfPlagiarismRemoval && project.typeOfPlagiarismRemoval.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Type of Plagiarism Removal
                </MKTypography>
                        {_.startCase(project.typeOfPlagiarismRemoval)}
                    <br/>
                <br/>
            </>
        }


        {project.percentageOfSimilartyIndex && project.percentageOfSimilartyIndex.length > 0 &&
            <>
                <MKTypography variant="body2" color="dark" fontWeight="regular">
                    Percentage of Similarity Index
                </MKTypography>
                        {_.startCase(project.percentageOfSimilartyIndex)}
                    <br/>
                <br/>
            </>
        }
        </ProjectCollapse>
    </>
  );
}

DetailedInfo.defaultProps = {
    project: {}
}

DetailedInfo.propTypes = {
    project: PropTypes.objectOf(PropTypes.string),
}

export default DetailedInfo;
