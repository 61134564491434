

import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components


// Routes
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import EmptyPayment from "./components/EmptyPayment";
import SinglePayment from "./components/SinglePayment";
import SkeletonPayment from "./components/SkeletonPayment";
import { getPayments } from "redux/payment/action";

function Payments() {
    const paymentDetails = useSelector(state => state.paymentState);
    const userInfoState = useSelector(state => state.userInfoState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfoState.userInfo !== undefined &&
            userInfoState.userInfo.authToken.length > 0 &&
            (paymentDetails.paymentDetailState.state === "NOT_STARTED" ||
                paymentDetails.paymentDetailState.state === "FAILED"))
            dispatch(getPayments(userInfoState.userInfo.authToken))
    }, [])


    return (
        <>

            {paymentDetails.paymentDetailState.state === 'LOADING' ?
                <Grid container mx={2}>
                    {[1, 2, 3].map((val, index) => <SkeletonPayment key={index} />)}
                </Grid>
                :

                paymentDetails.paymentDetailState.state === 'COMPLETE' ?
                    paymentDetails.paymentDetails.length>0 ?
                        <Grid container mx={3}>
                            {paymentDetails.paymentDetails.map((payment, index) =>
                                <SinglePayment
                                    key={payment.paymentId}
                                    payment={payment}
                                />
                            )
                            }
                        </Grid>
                        :
                        <EmptyPayment />
                    
                    :
                    <EmptyPayment />
            }

        </>

    )
}

export default Payments;