

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBlogCard from "components/Cards/BlogCards/DefaultBlogCard";

function Posts() {
  return (
    <MKBox component="section" pt={20} pb={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={9} textAlign="center" mx="auto">
            <MKTypography variant="h3" mb={0.5}>
              See other articles
            </MKTypography>
            <MKTypography variant="body2" color="text" px={{ xs: 0, md: 6 }} mb={4}>
            Browse and navigate through a collection of article titles. Convenient way to discover related content or explore different sections.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              category={{ color: "primary", label: "research topic" }}
              title="Research topics and it's evolution"
              description="Following the internet revolution, research
                     methods have significantly evolved. Given the abundance of
                      information accessible and the global network connections..."
              author={{
                // image: author1,
                name: "Admin",
                date: "Posted on 04 March",
              }}
              action={{ type: "internal", route: "/blog/research-topic-are-evolving-post-internet-revolution" }}
            />
          </Grid>

          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              // image={post2}
              category={{ color: "info", label: "questionnaire reliability" }}
              title="Unveiling Questionnaire Reliability"
              description="Cronbach's Alpha is widely regarded as the primary
                     method for assessing the internal consistency of a questionnaire.
                      It is important to recognize that although Cronbach's Alpha is
                       a valuable tool."
              author={{
                // image: author2,
                name: "Admin",
                date: "Posted on 27 February",
              }}
              action={{ type: "internal", route: "/blog/exploring-questionnaire-reliability-testing-beyond-cronbachs-alpha" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              category={{ color: "info", label: "thesis writing" }}
              title="How to write thesis"
              description="Following the internet revolution, research
                     methods have significantly evolved. Given the abundance of
                      information accessible and the global network connections..."
              author={{
                // image: author1,
                name: "Admin",
                date: "Posted on 12 February",
              }}
              action={{ type: "internal", route: "/blog/how-to-write-thesis" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Posts;
