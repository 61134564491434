import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "components/Cards/PricingCards/DefaultPricingCard";
import { useDispatch, useSelector } from "react-redux";
import { setProjectDetails } from "redux/projectDetails/action";
import _ from 'lodash';

function Pricing({ state, dispatch }) {
  const reduxDispatch = useDispatch();
  const userInfoState = useSelector(state => state.userInfoState);

  const getQuoteClick = (packageId, packageName) => {

    // if (!userInfoState.isUserLoggedIn) {
    //   // setIsLoginModalOpen(true);
    // } else if (userInfoState.userInfo !== undefined) {
    reduxDispatch(setProjectDetails({
      projectId: '',
      status: "Submitted for review",
      prices: [{
        label: '',
        price: 0
      },],
      pursuingField: state.pursuingField,
      domainOfResearch: state.domainOfResearch,
      topicOfResearch: state.topicOfResearch,
      documentNeeded: Object.keys(_.pickBy(state.documentNeeded, (value) => value === true)),
      submissionDate: state.submissionDate,
      objective: state.objective,
      proposedTitle: state.proposedTitle,
      typeOfResearch: state.typeOfResearch,
      programOfStudy: state.programOfStudy,
      researchQuestions: state.researchQuestions.split('\n'),
      method: state.method,
      literatureReview: state.literatureReview,
      additionalRequirements: state.additionalRequirements,
      specifiedJournal: state.specifiedJournal,
      journalFormat: state.journalFormat,
      typeOfPlagiarismRemoval: state.typeOfPlagiarismRemoval,
      percentageOfSimilartyIndex: state.percentageOfSimilartyIndex,
      createdAt: '',
      packageDetails: {
        packageId: packageId,
        packageName: packageName,
        packagePrice: 0,
        packageFeatures: [],
      }
    }, userInfoState.userInfo?.authToken));
    // }
  }


  return (
    <MKBox component="section" py={{ xs: 0, lg: 7 }}>

      <MKBox mt={-0}>
        <Container>
          <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "starter" }}
                  // price={{ currency: "$", value: tabType === "annual" ? 119 : 59, type: "mo" }}
                  specifications={[
                    { label: "2 google meet session", includes: true },
                    { label: "Report with analytics", includes: true },
                    { label: "Novelty finding", includes: true },
                    { label: "Plagirism check", includes: true },
                    { label: "30 days validity", includes: false },
                    { label: "Presentation with video", includes: false },
                    { label: "Priority delivery", includes: false },
                  ]}
                  action={{
                    type: "callback",
                    callback: () => getQuoteClick(0, "Starter"),
                    // route: "/",
                    color: "dark",
                    label: "Get quote",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  color="dark"
                  badge={{ color: "info", label: "advanced" }}
                  // price={{ currency: "$", value: tabType === "annual" ? 159 : 89, type: "mo" }}
                  specifications={[
                    { label: "4 google meet session", includes: true },
                    { label: "Report with analytics", includes: true },
                    { label: "Novelty finding", includes: true },
                    { label: "Plagirism check", includes: true },
                    { label: "30 days validity", includes: true },
                    { label: "Presentation with video", includes: true },
                    { label: "Priority delivery", includes: false },
                  ]}
                  action={{
                    type: "callback",
                    callback: () => getQuoteClick(1, "Advanced"),
                    color: "info",
                    label: "Get quote",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "premium" }}
                  // price={{ currency: "$", value: tabType === "annual" ? 99 : 399, type: "mo" }}
                  specifications={[
                    { label: "4 google meet session", includes: true },
                    { label: "Report with analytics", includes: true },
                    { label: "Novelty finding", includes: true },
                    { label: "Plagirism check", includes: true },
                    { label: "30 days validity", includes: true },
                    { label: "Presentation with video", includes: true },
                    { label: "Priority delivery", includes: true },
                  ]}
                  action={{
                    type: "callback",
                    callback: () => getQuoteClick(2, "Premium"),
                    color: "dark",
                    label: "Get quote",
                  }}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Pricing;