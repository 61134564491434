import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserSignout } from "redux/userInfo/action";


function SignOut() {
    const navigate = useNavigate();
  
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(setUserSignout());

      // Clear redux store data
      dispatch({
        type: 'RESET_PROJECT_DETAILS',
      });
      dispatch({
        type: 'RESET_PAYMENT_DETAIL',
      })
      navigate("/")
    }, [])
    

    return(
        <>
        </>
    )

}

export default SignOut