

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// HelpCenter page components

// Routes

import { LinearProgress, Skeleton } from "@mui/material";
import { PROJECT_TNC } from "../defs";
import ProjectCollapse from "../ProjectCollapse";



function ProjectTnc() {
    return (
        <>
            {/* <MKBox
                variant="gradient"
                bgColor="light"
                borderRadius="lg"
                coloredShadow="dark"
                px={3}
            > */}
                <ProjectCollapse
                    title="Terms and Conditions"
                >
                    {PROJECT_TNC.map((tnc, index) =>
                        <>
                            {index + 1}. {tnc} <br />
                        </>

                    )}
                </ProjectCollapse>
            {/* </MKBox> */}
        </>
    );
}


ProjectTnc.defaultProps = {
}

ProjectTnc.propTypes = {
}

export default ProjectTnc;
