// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="payment"
                title="Content Analysis and Data Interpretation"
                description="We provide detailed content analysis and data interpretation to support your research findings with accuracy and clarity."
              />
              <SimpleInfoCard
                icon="insights"
                title="Custom Research Proposal Development"
                description="Develop a compelling and original research proposal that clearly defines your research objectives and methodology."
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="access_alarms"
                title="Plagiarism-Free Guarantee"
                description="We ensure all work is free from plagiarism and offer a revision period to address any concerns or updates needed."
              />
              <SimpleInfoCard
                icon="sentiment_satisfied"
                title="Fast Turnaround Times with Quality Assurance"
                description="Delivering high-quality research papers and theses within your deadline without compromising on the standards."
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
