import { Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getDate_DDMMYYYY } from "utility/date";
import _ from 'lodash';


function SingleProject({ project }) {
    const navigate = useNavigate();

    return (
        <>
            <MKBox
                variant="gradient"
                bgColor="none"
                borderRadius="lg"
                coloredShadow="light"
                width="100%"
                height="auto"
                px={3}
                py={3}
                mb={3}
                sx={{
                    cursor: "pointer"
                }}
                onClick={()=>navigate("/project/" + project.projectId)}
            >
                <MKTypography variant="body2" color="dark" fontWeight="bold"  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                    {_.startCase(project.topicOfResearch)}
                </MKTypography>

                <Grid container mt={2} gap={1}>
                    <MKTypography variant="body2" color="text" fontWeight="bold">
                        Project ID:
                    </MKTypography>
                    <MKTypography variant="body2" color="text" fontWeight="regular" textTransform="uppercase">
                        {project.projectId}&nbsp;&#8226;&nbsp;
                    </MKTypography>
                    <MKTypography variant="body2" color="text" fontWeight="bold">
                        Submitted on:
                    </MKTypography>
                    <MKTypography variant="body2" color="text" fontWeight="regular">
                        {getDate_DDMMYYYY(project.createdAt)} &nbsp;&#8226;&nbsp;
                    </MKTypography>

                    <MKTypography variant="body2" color="text" fontWeight="bold">
                        Status
                    </MKTypography>

                    <MKBox
                        variant="contained"
                        bgColor="info"
                        borderRadius="xxl"
                        coloredShadow="dark"
                        px={1}
                        mx={2}
                    >
                        <MKTypography variant="body2" color="white" fontWeight="regular">
                            {project.status}
                        </MKTypography>
                    </MKBox>


                </Grid>
            </MKBox>

        </>

    )
}

SingleProject.defaultProps = {
    project: {}
}

SingleProject.propTypes = {
    project: PropTypes.objectOf(PropTypes.string),
}

export default SingleProject;