

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

function Information() {
  return (
    <MKBox component="section" pt={6} pb={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" component="h3">Trusted by over <br/></MKTypography>          
          <MKTypography variant="h2" color="info" textGradient mb={2} component="h3">
            5,000+ academic partners
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Discover why over 5,000 academic partners trust our services. Join a community of satisfied clients who have achieved their academic goals with our support.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
