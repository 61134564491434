

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function FairUsagePolicy() {
  const userInfoState = useSelector(state => state.userInfoState);
  return (
    <>
      <Helmet>
        <title>Innovus Analytics | Fair Usage Policy | Expert Writing services in India | Fair usage policy </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/fair-usage-policy/" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white" component="h2">
                    Fair Usage Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: Jan 07 2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    At Innovus Analytics, accessible{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://www.innovusanalytics.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      here
                    </MKTypography>
                    , we adhere to a Fair Usage Policy to maintain academic integrity
                    and uphold ethical standards in research. Our services are designed to provide
                    support and guidance to research candidates throughout their academic journey.
                    We offer consultation, research assistance, and expert guidance in various aspects
                    of research, including research design, editing, and statistical analysis.
                    <br />
                    <br />
                    It is important to note that the papers provided by Innovus Analytics are intended
                    to serve as model papers for reference purposes only. They are not to be submitted
                    'as is' for academic credit. Our papers are meant to assist students in understanding
                    complex concepts, formatting guidelines, and research methodologies.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3} component="h4">
                    Managing Our Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We encourage our users to utilize our services responsibly and ethically. Our
                    platform does not promote academic dishonesty or plagiarism in any form. Users
                    are expected to use the papers provided by Innovus Analytics as a guide to enhance
                    their own understanding of the subject matter and to aid in the development
                    of their research skills.
                    <br />
                    <br />
                    By accessing and using our services, users acknowledge and agree to adhere to our Fair
                    Usage Policy. Any misuse or unauthorized use of our services may result in disciplinary action, including account suspension or termination.
                    <br />
                    <br />
                    Thank you for choosing
                    <MKTypography
                      variant="body2"
                      color="text"
                      component="a"
                      href="https://www.innovusanalytics.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                      fontWeight="regular"
                    >
                      {" "}Innovus Analytics{" "}
                    </MKTypography>
                    as your trusted partner in academic excellence. Should you have any questions or concerns regarding our
                    Fair Usage Policy, please don't hesitate to contact us at hello@innovusanalytics.com.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default FairUsagePolicy;
