
import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";
import Separator from "pages/Authentication/components/Separator";
import Socials from "pages/Authentication/components/Socials";


// Images
import bgImage from "assets/images/bg-sign-in-basic.webp";
import { useDispatch, useSelector } from "react-redux";
import { signupUserUsingEmail } from "redux/userInfo/action";
import { Helmet } from "react-helmet";

function SignUp() {
  const navigate = useNavigate();
  const [isNameOk, setIsNameOk] = useState(true);
  const [name, setName] = useState('');
  const [isEmailOk, setIsEmailOk] = useState(true);
  const [email, setEmail] = useState('');
  const [isPasswordOk, setIsPasswordOk] = useState(true);
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const userInfoState = useSelector(state => state.userInfoState);
  const handleSubmit = async (e) => {
      e.preventDefault();

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(email);
      setIsNameOk(name.length > 0);
      setIsEmailOk(email.length > 0 && isValidEmail);
      setIsPasswordOk(password.length > 0);

      if (name.length === 0 || email.length === 0 || !isValidEmail || password.length === 0) return;
      dispatch(signupUserUsingEmail(name, email, password));
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");
  useEffect(() => {
      if (userInfoState.isUserLoggedIn) {
          if (redirectUrl)
              navigate(redirectUrl);
          else
              navigate('/');
      }
  }, [userInfoState.isUserLoggedIn])

  const onLoginLinkClick = () => {
      if (redirectUrl)
          return '/signin?redirect=' + redirectUrl;
      else
          return '/signin';
  }

  return (
    <>
      <Helmet>
        <title>Thesis writing services | Expert Writing services in India | Sign up </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/signup/" />
      </Helmet>
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1} component="h3">
            Join us today
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput type="text" label="Name" fullWidth 
              error={!isNameOk} value={name} onChange={(e) => setName(e.target.value)}/>
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="email" label="Email" fullWidth 
              error={!isEmailOk} value={email} onChange={(e) => setEmail(e.target.value)}/>
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="password" label="Password" fullWidth 
              error={!isPasswordOk} value={password} onChange={(e) => setPassword(e.target.value)}/>
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth 
              onClick={handleSubmit}>
                sign up
              </MKButton>
            </MKBox>
            <Separator />
            <Socials />
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to={onLoginLinkClick()}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>


      </Card>
    </BasicLayout>
    </>
  );
}

export default SignUp;
