
// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setUserDetail } from "redux/userInfo/action";
import { setUserInfo } from "redux/userInfo/action";

function GoogleSocial() {

    const dispatch = useDispatch();

    useEffect(() => {
        // Add an event listener to handle the message event from the popup window
        window.addEventListener('message', handleMessageEvent);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessageEvent);
        };
    }, []);

    const handleMessageEvent = (event) => {
        if (event.origin === window.location.origin && event.data.authToken) {
            let userInfo = {
                userName: event.data.userName,
                userEmail: event.data.userName,
                authToken: event.data.authToken,
            }
            dispatch(setUserInfo(userInfo));
        }
    };

    const handleGoogleLogin = () => {
        const width = 500; // Popup window width
        const height = 600; // Popup window height
        const left = window.innerWidth / 2 - width / 2; // Calculate center position
        const top = window.innerHeight / 2 - height / 2; // Calculate center position

        const popupWindow = window.open('/api/auth/google', '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);

        // Check if the popup window is closed or blocked by the browser
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            // Handle the case where the popup window is blocked or closed
            // For example, show an error message or fallback to a different authentication method
            console.log('Google Sign-In popup was blocked or closed');
        }
    };

  return (

      <MKButton variant="outlined" color="light" sx={{ width: "100%" }} onClick={handleGoogleLogin}>
        <MKBox display="flex" justifyContent="center" alignItems="center" gap={2}>
          <MKBox>
        <svg width="24px" height="24px" viewBox="0 0 64 64" version="1.1">
          {" "}
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(3.000000, 2.000000)" fillRule="nonzero">
              <path
                d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                fill="#4285F4"
              />
              <path
                d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                fill="#34A853"
              />
              <path
                d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                fill="#FBBC05"
              />
              <path
                d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                fill="#EB4335"
              />
            </g>
          </g>
        </svg>
        </MKBox>
        Sign in with Google
        </MKBox>
      </MKButton>
  );
}

export default GoogleSocial;
