
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

// DesktopApp page sections
// import Banner from "pages/Apps/DesktopApp/sections/Banner";
// import Information from "pages/Apps/DesktopApp/sections/Information";
// import Features from "pages/Apps/DesktopApp/sections/Features";
// import Testimonials from "pages/Apps/DesktopApp/sections/Testimonials";
// import Pricing from "pages/Apps/DesktopApp/sections/Pricing";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import MKTypography from "components/MKTypography";
import NavTabs from "./sections/Navtabs";
import BasicInformation from "./sections/BasicInformation";
import DetailedInformation from "./sections/DetailedInformation";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useReducer, useState } from "react";
import { newProjectInitialState, newProjectReducer, newProjectValidationInitialState } from "./newproject.reducer";
import _ from "lodash";
import { isEthicsRelatedProjectSelected, isJournalRelatedProjectSelected, isResearchRelatedProjectSelected } from "./utils";
import { Box } from "@mui/material";
import Pricing from "./sections/Pricing";
import { Helmet } from "react-helmet";


function NewProject() {
  const { id } = useParams();
  const userInfoState = useSelector(state => state.userInfoState);
  const projectDetailState = useSelector(state => state.projectDetailsState);
  const navigate = useNavigate();

  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(newProjectReducer,
    newProjectInitialState);

  const handleNext = () => {

    // Before moving ahead, validate the form
    let validationState = { ...newProjectValidationInitialState };

    let isValid = true;
    if (state.activeTab === 0) {
      validationState.pursuingField = state.pursuingField.length > 0;
      validationState.domainOfResearch = state.domainOfResearch.length > 0;
      validationState.topicOfResearch = state.topicOfResearch.length > 0;
      validationState.documentNeeded = _.keys(_.pickBy(state.documentNeeded, value => value === true)).length > 0;
      isValid = validationState.pursuingField && validationState.domainOfResearch && validationState.topicOfResearch && validationState.documentNeeded;
    }
    if (state.activeTab === 1) {
      if (isJournalRelatedProjectSelected(state.documentNeeded) || isResearchRelatedProjectSelected(state.documentNeeded)) {
        validationState.objective = state.objective.length > 0;
        validationState.researchQuestions = state.researchQuestions.length > 0;
        validationState.method = state.method.length > 0;
      }
      if (isJournalRelatedProjectSelected(state.documentNeeded)) {
        validationState.literatureReview = state.literatureReview.length > 0;
        validationState.journalFormat = state.journalFormat.length > 0;
      }
      if (isEthicsRelatedProjectSelected(state.documentNeeded)) {
        validationState.typeOfPlagiarismRemoval = state.typeOfPlagiarismRemoval.length > 0;
        validationState.percentageOfSimilartyIndex = state.percentageOfSimilartyIndex.length > 0;
      }
      isValid = validationState.objective && validationState.researchQuestions && validationState.method && validationState.literatureReview && validationState.journalFormat && validationState.typeOfPlagiarismRemoval && validationState.percentageOfSimilartyIndex;
    }
    dispatch({ type: 'SET_VALIDATION_STATE', payload: validationState });
    if (isValid) {
      if (state.activeTab === 0)
        dispatch({ type: 'SET_ACTIVE_STEP', payload: state.activeTab + 1 });
      else {
        // Make a post request
        dispatch({ type: 'SET_ACTIVE_STEP', payload: state.activeTab + 1 });
      }
    }
  };


  useEffect(() => {
    if (projectDetailState.projectSavingState != undefined && projectDetailState.projectSavingState.isSaving)
      navigate("/projects");
  }, [projectDetailState.projectSavingState])



  return (
    <>
      <Helmet>
        <title>Thesis writing services | Expert Writing services in India | New project </title>
        <meta name="description" content="Academic services thesis writing service, research synoposis, dissertation etc." />
        <meta name="keywords" content="thesis, phd thesis, thesis writing, research synopsis, dissertation help, paper helper, dissertation synopsis, dissertation writing, master thesis, phd assistance, phd guidance, phd topics, " />
        <link rel="canonical" href="https://www.innovusanalytics.com/new-project/" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        visibleIfLoggedIn={userInfoState.isUserLoggedIn}
        action={userInfoState.isUserLoggedIn ? undefined : {
          type: "internal",
          route: "/signup",
          label: "sign up",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
            linearGradient(gradients.dark.main, gradients.dark.state),
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={8}
            lg={4}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              component="h1"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {state.activeTab < 2 ? "Get started" : "Select Package"}
            </MKTypography>

            <MKTypography variant="body1" color="white" mt={1}>
              {state.activeTab < 2 ? "Share your requirement for your project" :
                "Explore our flexible package options tailored to meet your needs"}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -12,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {state.activeTab < 2 &&
          <NavTabs
            state={state}
            dispatch={dispatch}
          />
        }
        <MKBox sx={{

          animation: _.keys(_.pickBy(state.validationState, value => value === false)).length > 0 ? 'shake 0.5s' : '',
          animationIterationCount: 1,
          '@keyframes shake': {
            '10%': { transform: 'translate3d(-1px, 0, 0)' },
            '20%': { transform: 'translate3d(2px, 0, 0)' },
            '30%': { transform: 'translate3d(-4px, 0, 0)' },
            '40%': {
              transform: 'translate3d(4px, 0, 0)'
            },
            '50%': {
              transform: 'translate3d(-4px, 0, 0)'
            },
            '60%': {
              transform: 'translate3d(4px, 0, 0)'
            },
            '70%': {
              transform: 'translate3d(-4px, 0, 0)'
            },
            '80%': {
              transform: 'translate3d(2px, 0, 0)'
            },
            '90%': {
              transform: 'translate3d(-1px, 0, 0)'
            }
          },
        }}>
          {state.activeTab == 0 &&
            <BasicInformation
              state={state}
              dispatch={dispatch}
              handleNext={handleNext}
            />
          }
          {state.activeTab == 1 &&
            <DetailedInformation
              state={state}
              dispatch={dispatch}
              handleNext={handleNext}
            />
          }
          {state.activeTab == 2 &&
            <Pricing
              state={state}
              dispatch={dispatch}
            />
          }
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default NewProject;
