import { loginUsingEmailAPI, signupUsingEmailAPI } from "../../api/login.api";
import { getUserDetailInfoAPI, setUserDetailInfoAPI } from "../../api/user.api";
// import { ISetUserDetailPostData } from "../../types/apidata";
import { deleteAuthTokenCookie, setAuthTokenCookie } from "../../utility/cookie";

export const loginUserUsingEmail = (email, password) => {
    // Simulate an async login request and dispatch an action with the user info
    return async (dispatch) => {
        try {
            dispatch({
                type: 'SET_LOGIN_SIGNUP_LOADING',
            });
            const data = await loginUsingEmailAPI(email, password);
            if (data.status === 200) {
                // dispatch({
                //     type: 'SET_USER_INFO',
                //     payload: data.data,
                // });
                dispatch(setUserInfo(data.data));
            } else {
                dispatch({
                    type: 'SET_LOGIN_FAILED',
                    payload: "Login failed. Please check your credentials.",
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_LOGIN_FAILED',
                payload: "Login failed. Please check your credentials.",
            })
        }
    };
};

export const signupUserUsingEmail = (name, email, password) => {
    // Simulate an async login request and dispatch an action with the user info
    return async (dispatch) => {
        try {
            const data = await signupUsingEmailAPI(name, email, password);
            if (data.status === 200 || data.status === 201) {
                // dispatch({
                //     type: 'SET_USER_INFO',
                //     payload: data.data,
                // });
                dispatch(setUserInfo(data.data));
            } else if (data.data.message === "user_already_exists") {
                dispatch({
                    type: 'SET_LOGIN_FAILED',
                    payload: "User already exists. Please login.",
                })
            }
        } catch (error) {
            console.log('data: ', error);
            dispatch({
                type: 'SET_LOGIN_FAILED',
                payload: "Signup failed. Please try again.",
            })
        }
    };
};

export const setUserSignout = () => {
    deleteAuthTokenCookie();
    return {
        type: 'SET_USER_SIGNOUT',
    }
}

export const setUserInfo = (userInfo) => {
    setAuthTokenCookie(userInfo.authToken);
    return {
        type: 'SET_USER_INFO',
        payload: userInfo
    }
}

export const resetErrorAndLoadingState = () => {
    return {
        type: 'RESET_ERROR_AND_LOADING_STATE',
    }
}

export const getUserInfo = (authentication) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_LOGIN_SIGNUP_LOADING' });
            const data = await getUserDetailInfoAPI(authentication);
            if (data.status === 200) {
                dispatch({
                    type: 'SET_USER_INFO',
                    payload: {
                        userName: data.data.name,
                        userEmail: data.data.email,
                        authToken: authentication
                    },
                });
            } else {
                dispatch({
                    type: 'SET_USER_INFO_FAILED',
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_USER_INFO_FAILED',
            })
        }
    };
}

export const getUserDetail = (authentication) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'SET_USER_DETAIL_LOADING',
            });
            const data = await getUserDetailInfoAPI(authentication);
            if (data.status === 200) {
                dispatch({
                    type: 'SET_USER_DETAIL',
                    payload: {
                        userMobile: data.data.phone,
                        isUserVerified: undefined,
                        userAddress: {
                            addressLine1: data.data.address,
                            locality: data.data.locality,
                            city: data.data.city,
                            state: data.data.state,
                            country: data.data.country,
                            pincode: data.data.zip,
                        }
                    },
                });
            } else {
                dispatch({
                    type: 'SET_USER_DETAIL_FAILED',
                    payload: true,
                })
            }
        } catch (error) {
            dispatch({
                type: 'SET_USER_DETAIL_FAILED',
                payload: true,
            })
        }
    };
}

export const setUserDetail = (authentication, userDetail) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'SET_USER_DETAIL_LOADING',
            });
            const data = await setUserDetailInfoAPI(authentication, userDetail);
            if (data.status === 201) {
                dispatch({
                    type: 'SET_USER_DETAIL',
                    payload: {
                        userName: userDetail.name,
                        userMobile: userDetail.phone,
                        userAddress: {
                            addressLine1: userDetail.address,
                            locality: userDetail.locality,
                            city: userDetail.city,
                            state: userDetail.state,
                            country: userDetail.country,
                            pincode: userDetail.zip,
                        }
                    },
                });
            }
        } catch (error) {
            dispatch({
                type: 'SET_USER_DETAIL_FAILED',
                payload: true,
            })
        }
    };
}