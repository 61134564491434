// import { IUserInfoState } from "../../types/redux"
// import { IProjectsInfoModelState, IUserInfoModelState } from "../../types/model"

// type Action =
//     | { type: 'SET_USER_INFO'; payload: IUserInfoModelState }
//     | { type: 'SET_USER_INFO_FAILED' }
//     | { type: 'SET_LOGIN_FAILED'; payload: string }
//     | { type: 'SET_USER_SIGNOUT'; payload: undefined }
//     | { type: 'SET_LOGIN_SIGNUP_LOADING'; payload: undefined }
//     | { type: 'RESET_ERROR_AND_LOADING_STATE'; payload: undefined }
//     | { type: 'SET_USER_DETAIL_LOADING'; payload: undefined }
//     | { type: 'SET_USER_DETAIL'; payload: IUserInfoModelState }
//     | { type: 'SET_USER_DETAIL_FAILED'; payload: undefined }
// | { type: 'SER_PROJECTS_INFO'; payload: IProjectsInfoModelState }

const initialState = {
    isUserLoggedIn: false,
    loginLoadingState: {
        isLoading: false,
        isLoaded: false,
        isLoadingFailed: false,
        loadingMessage: ""
    },

    userInfo: {
        userName: "",
        userEmail: "",
        authToken: "",
        userRole: undefined,
        userMobile: undefined,
        isUserVerified: undefined,
        userAddress: undefined
    },
    userDetailsLoadingState: {
        isLoading: false,
        isLoaded: false,
        isLoadingFailed: false,
        loadingMessage: ""
    },

}

const userInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_INFO':
            return {
                ...state,
                isUserLoggedIn: true,
                userInfo: {
                    userName: action.payload.userName,
                    userEmail: action.payload.userEmail,
                    authToken: action.payload.authToken,
                },
                loginLoadingState: {
                    isLoading: false,
                    isLoaded: true,
                    isLoadingFailed: false,
                }
            }
        case 'SET_USER_INFO_FAILED':
            return {
                ...state,
                isUserLoggedIn: false,
                loginLoadingState: {
                    isLoading: false,
                    isLoaded: false,
                    isLoadingFailed: true,
                }
            }
        case 'SET_LOGIN_FAILED':
            return {
                ...state,
                loginLoadingState: {
                    isLoading: false,
                    isLoaded: false,
                    isLoadingFailed: true,
                    loadingMessage: action.payload
                },
                isUserLoggedIn: false,
                userInfo: undefined,
            }
        case 'SET_USER_SIGNOUT':
            return {
                ...state,
                isUserLoggedIn: false,
                userInfo: undefined
            }
        case 'SET_LOGIN_SIGNUP_LOADING':
            return {
                ...state,
                loginLoadingState: {
                    isLoading: true,
                    isLoaded: false,
                    isLoadingFailed: false,
                }
            }
        case 'RESET_ERROR_AND_LOADING_STATE':
            return {
                ...state,
                loginLoadedState: {
                    isLoading: false,
                    isLoaded: false,
                    isLoadingFailed: false,
                    loadingMessage: ""
                }
            }
        case 'SET_USER_DETAIL_LOADING':
            return {
                ...state,
                userDetailsLoadingState: {
                    isLoading: true,
                    isLoaded: false,
                    isLoadingFailed: false,
                }
            }
        // case 'SER_PROJECTS_INFO':
        //     return {
        //         ...state,
        //         projectsInfo: action.payload
        //     }
        case 'SET_USER_DETAIL_FAILED':
            return {
                ...state,
                userDetailsLoadingState: {
                    isLoading: false,
                    isLoaded: false,
                    isLoadingFailed: true,
                }
            }
        case 'SET_USER_DETAIL':
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    userName: action.payload.userName !== undefined ? action.payload.userName : state.userInfo?.userName,
                    userRole: action.payload.userRole,
                    userMobile: action.payload.userMobile,
                    isUserVerified: action.payload.isUserVerified,
                    userAddress: {
                        addressLine1: action.payload.userAddress.addressLine1,
                        locality: action.payload.userAddress.locality,
                        city: action.payload.userAddress.city,
                        state: action.payload.userAddress.state,
                        country: action.payload.userAddress.country,
                        pincode: action.payload.userAddress.pincode,
                    }
                },
                userDetailsLoadingState: {
                    isLoading: false,
                    isLoaded: true,
                    isLoadingFailed: false,
                }
            }
        default: return state
    }
}

export default userInfoReducer